import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, PseudoBox } from '@chakra-ui/core'
import { useTranslation } from 'react-i18next'
import { getToken, getUserInformation } from '../../../redux/selectors'
import SelectAsync from '../../common/select-async'
import { getAllAplicationTypes, getSuppliers } from '../../../api/intranet/requests'
import { getAllCitiesQuery } from '../../../api/geo'
import { useSelector } from 'react-redux'
import { DateRangePicker } from 'react-dates'
import { Select } from 'antd'
import { FILTER_KEYS } from './package-section-data'
import { useSectionData } from '../../../hooks/useSectionData'
import './styles.css'

export const PackageSearchForm = ({ onFilter, ...restProps }) => {
  const { filters, handleFilters, handleResetFilters } = useSectionData()
  const { t } = useTranslation(['common', 'comunications', 'forms', 'packages'])
  const token = useSelector(getToken)
  const user = useSelector(getUserInformation)

  const { Option } = Select

  //TRAER PROVEEDORES CON PAQUETES
  const [supplierWithPackage, setSupplierWithPackage] = useState({
    isLoading: false,
    items: [],
    error: false,
  })

  //TRAER TIPOS DE APLICACIONES / EMPRESAS
  const [aplicationTypes, setAplicationTypes] = useState({
    isLoading: false,
    items: [],
    error: false,
  })

  async function getAplicationTypes() {
    setAplicationTypes((state) => ({
      ...state,
      isLoading: true,
    }))
    try {
      const res = await getAllAplicationTypes(token)
      setAplicationTypes((state) => ({
        ...state,
        isLoading: false,
        items: (res.status && res.data) || [],
      }))
    } catch (error) {
      setAplicationTypes((state) => ({
        ...state,
        isLoading: false,
        error: true,
      }))
    }
  }
  useEffect(() => {
    getAplicationTypes()
  }, [])

  //TRAER TODOS LOS PROVEEDORES
  const [allSuppliers, setAllSuppliers] = useState({
    isLoading: false,
    items: [],
    error: false,
  })

  async function getProviders() {
    const supplier = supplierWithPackage.items.map((supp) => {
      return aplicationTypes.items?.filter((app) => app.id === supp)
    })

    setSupplierWithPackage((state) => ({
      ...state,
      items: supplier.flat(),
      isLoading: false,
    }))

    try {
      const data = {
        paisId: '',
        categoria: '',
        nombre: '',
      }
      const res = await getSuppliers(data, token)
      setAllSuppliers((state) => ({
        ...state,
        isLoading: false,
        items: (res.status && res.data) || [],
      }))
    } catch (error) {
      console.log(error)
      setAllSuppliers((state) => ({
        ...state,
        isLoading: false,
        error: true,
      }))
    }
  }
  useEffect(() => {
    getProviders()
  }, [aplicationTypes])

  let allSupplierCustom = supplierWithPackage.items?.map((sup) => {
    if (sup.id) {
      return { ...sup, id: sup.id + '-' + sup.descripcion }
    }
  })

  allSupplierCustom = [
    {
      id: user.aplicacion.id,
      descripcion: 'Mis paquetes',
    },
    ...allSupplierCustom,
  ]

  // Selector de rango de fechas
  const [{ startDate, endDate }, setRangePicker] = useState({
    startDate: undefined,
    endDate: undefined,
  })

  const [focusedInput, setFocusedInput] = useState(null)

  function setFocusedPicker(focusedInput) {
    setFocusedInput(() => focusedInput)
  }

  // Selectores de ciudades origen y destino

  const [cityOrigins, setCityOrigins] = useState({
    isLoading: false,
    items: [],
    error: false,
  })
  const [cityDestinations, setCityDestinations] = useState({
    isLoading: false,
    items: [],
    error: false,
  })

  async function getCityOrigins(city) {
    setCityOrigins((state) => ({
      ...state,
      isLoading: true,
    }))
    try {
      const res = await getAllCitiesQuery({ search: city, origins: true })
      setCityOrigins(() => ({
        error: false,
        isLoading: false,
        items: res.data || [],
      }))
    } catch (error) {
      setCityOrigins(() => ({
        items: [],
        isLoading: false,
        error: true,
      }))
    }
  }
  async function getCityDestinationas(city) {
    setCityDestinations((state) => ({
      ...state,
      isLoading: true,
    }))
    try {
      const res = await getAllCitiesQuery({ search: city, destinations: true })
      setCityDestinations(() => ({
        error: false,
        isLoading: false,
        items: res.data || [],
      }))
    } catch (error) {
      setCityDestinations(() => ({
        items: [],
        isLoading: false,
        error: true,
      }))
    }
  }

  function handleSearchOrigins(value) {
    if (value.length > 2) {
      getCityOrigins(value)
    } else {
      setCityOrigins({
        isLoading: false,
        items: [],
        error: false,
      })
    }
  }

  function handleSearchDestinations(value) {
    if (value.length > 2) {
      getCityDestinationas(value)
    } else {
      setCityDestinations({
        isLoading: false,
        items: [],
        error: false,
      })
    }
  }

  // Limpiar los filtros
  function handleResetInputs() {
    setRangePicker({ undefined, undefined })
  }

  return (
    <PseudoBox
      {...restProps}
    >
      <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(4, minmax(0, 25%))' }} gap={6}>
        {/* Filtro Origen */}
        <Box>
          <Select
            style={{display: 'block'}}
            getPopupContainer={(triggerNode) =>
              triggerNode.parentNode
            }
            id="citiesToSearch"
            name="citiesToSearch"
            showSearch
            onChange={(value) => {
              handleFilters(FILTER_KEYS.originCode, value)
            }}
            placeholder={t('packages:origin')}
            allowClear
            value={filters.originCode}
            onSearch={handleSearchOrigins}
          >
            {cityOrigins.items?.map(({id, nombre, provincia}) => (
              <Option key={id} value={nombre} >
                {`${nombre} - (${provincia.nombre} | ${provincia.pais.nombre || ''})`}
              </Option>
            ))}
          </Select>
        </Box>
        {/* Filtro Destino */}
        <Box>
          <Select
            style={{display: 'block'}}
            getPopupContainer={(triggerNode) =>
              triggerNode.parentNode
            }
            id="citiesToSearch"
            name="citiesToSearch"
            showSearch
            onChange={(value) => {
              handleFilters(FILTER_KEYS.destinationCode, value)
            }}
            placeholder={t('packages:destination')}
            allowClear
            value={filters.destinationCode}
            onSearch={handleSearchDestinations}
          >
            {cityDestinations.items?.map(({id, nombre, provincia}) => (
              <Option key={id} value={nombre} >
                {`${nombre} - (${provincia.nombre} | ${provincia.pais.nombre || ''})`}
              </Option>
            ))}
          </Select>
        </Box>

        {/* Filtro de fecha */}
        <Box>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            startDateId="fromDate"
            endDateId="untilDate"
            focusedInput={focusedInput}
            onDatesChange={({ startDate, endDate }) => {
              setRangePicker({ startDate: startDate, endDate: endDate })
              handleFilters(FILTER_KEYS.fromDate, startDate?.hours(0).minutes(0).toISOString(true))
              handleFilters(FILTER_KEYS.untilDate, endDate?.hours(23).minutes(59).toISOString(true))
            }}
            onFocusChange={(focusedInput) => setFocusedPicker(focusedInput)}
            small
            orientation="horizontal"
            displayFormat="DD/MM/YYYY"
            isOutsideRange={() => false}
            startDatePlaceholderText={t('common:startDate')}
            endDatePlaceholderText={t('common:endDate')}
          />
        </Box>

        {/* Proveedores
        <Box>
          <SelectAsync
            onChange={(e) => handleFilters(FILTER_KEYS.applicationId, e.target.value)}
            placeholder={`${t('forms:select')} ${t('forms:supplier')}`}
            value={filters.applicationId}
            isLoading={allSuppliers.isLoading}
          >
            {!allSuppliers.error &&
              allSupplierCustom &&
              allSupplierCustom.map((supplier) => {
                if (supplier !== undefined) {
                  return (
                    <option key={supplier.id} value={supplier.id}>
                      {supplier.descripcion}
                    </option>
                  )
                }
              })}
          </SelectAsync>
        </Box> */}

        {/* Filtrar Button */}
        <Grid style={{ display: 'flex', gap: '1rem' }} alignContent={'end'} templateColumns={'repeat(2, 1fr)'}>
          <Button maxW={{ base: '100%', md: '120px' }} variantColor='blue' variant='outline' onClick={onFilter}>
            {t('common:filter')}
          </Button>

          <Button variantColor='blue' variant='outline' onClick={() => {
            handleResetInputs()
            handleResetFilters()
            }}
          >
            {t('comunications:cleanFilter')}
          </Button>
        </Grid>
      </Grid>
    </PseudoBox>
  )
}
