import React, { useEffect, useMemo, useState } from 'react'
import {
  Box,
  Button,
  Grid,
  PseudoBox,
  FormLabel,
  Input,
  Tab,
  TabPanels,
  TabPanel,
  TabList,
  Tabs,
  Image,
  Select,
  Checkbox,
  Flex
} from '@chakra-ui/core'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { BANNERS_CREATE } from '../../../../helpers/permissions'
import { getCategoriesState, getUserCountryId, getUserInformation, getUserRolId } from '../../../../redux/selectors'
import SelectAsync from '../../../common/select-async'
import FormInput from '../../../forms/form-input'
import 'react-dates/initialize'
import { DateRangePicker } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'
import { useAsyncStateWithAction, usePermissionChecker } from '../../../hooks'
import ImageInput from '../../../common/image-input'
import { listCategories } from '../../../../redux/actions/banners-actions.js'
import moment from 'moment'
import ReactQuill, { snow } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import ImageInputV2 from '../../../common/image-input-v2'
import { IoMdTrash } from 'react-icons/io'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { List } from 'antd'
import './styles.css'
import { TAB_ITEMS } from '../../../../constants'

export default function BannerForm({ formData, onSubmit, handleCancelInBanner, deleteBannerOk, ...restProps }) {
  const userCountryId = useSelector(getUserCountryId)
  const { register, handleSubmit, formState, control, setValue, reset } = useForm({ defaultValues: formData })
  const { t } = useTranslation(['common', 'forms', 'banners', 'consultations'])
  const { canDoThis } = usePermissionChecker()
  const [esVideo, setEsVideo] = useState(formData ? formData.categoria.nombre.toLowerCase() === 'video' : false)
  const [contentValue, setContentValue] = useState('')
  const [contentValueItinerary, setContentValueItinerary] = useState('')
  const [contentValueIncludes, setContentValueIncludes] = useState('')
  const [contentValueHotels, setContentValueHotels] = useState('')
  const [contentValueRates, setContentValueRates] = useState('')
  const [contentValueOutputs, setContentValueOutputs] = useState('')
  const [isEdit, setIsEdit] = useState()
  const [activeTab, setActiveTab] = useState(0)
  const [characterCount, setCharacterCount] = useState(0)
  const [characterCount2, setCharacterCount2] = useState(0)
  const [characterCount3, setCharacterCount3] = useState(0)
  const [image, setImage] = useState('')
  const [bannerImagenes, setBannerImagenes] = useState([])
  const [categoryValue, setCategoryValue] = useState(undefined)
  const [isBannerX4, setIsBannerX4] = useState(true)
  const [isSaveButton, setIsSaveButton] = useState(true)
  const [isAnchoTotal, setIsAnchoTotal] = useState(true)
  const [showInDetail, setShowInDetail] = useState(false)
  const maxCharacterCount = 38
  const [ordenSecciones, setOrdenSecciones] = useState(Object.values(TAB_ITEMS))



  const onDragEnd = result => {
    const aux = Array.from(ordenSecciones)
    const [draggedItem] = aux.splice(result.source.index, 1)
    aux.splice(result.destination.index, 0, draggedItem)
    setOrdenSecciones(aux)
  }

  const user = useSelector(getUserInformation)
  const userRole = useSelector(getUserRolId)

  //Traer categorías
  const dispatchedGetCategories = dispatch => dispatch(listCategories(user.aplicacion.id, userCountryId))
  const { isLoading, items, update } = useAsyncStateWithAction(dispatchedGetCategories, getCategoriesState)

  /* Habilita o no el btn para crear */
  const permisoCreate = useMemo(
    () =>
      canDoThis(
        BANNERS_CREATE,
        () => ({
          ishidden: false,
        }),
        () => ({
          ishidden: true,
        }),
      ),
    [canDoThis],
  )

  // Hooks for the DateRangePicker
  const [{ startDate, endDate }, setRangeDate] = useState({
    startDate: moment(),
    endDate: null,
  })

  function setRangePicker({ startDate, endDate }) {
    setRangeDate({ startDate, endDate })
  }

  const [focusedInput, setFocusedInput] = useState({
    focusedInput: null,
  })

  function setFocusedPicker(focusedInput) {
    setFocusedInput(focusedInput)
  }

  const handleFormSubmit = formData => {
    const esConsulta = !esVideo || !isBannerX4 ? false : true
    setActiveTab(0)
    onSubmit({ ...formData, esConsulta, bannerImagenes, ordenSecciones })
    resetForm()
  }

  const customToolbar = [
    [{ font: [] }],
    [{ size: ['small', false, 'large', 'huge'] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ color: [] }, { background: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
    ['link'],
  ]

  const handleCancel = () => {
    resetForm()
    reset({})
    setIsEdit(false)
    handleCancelInBanner()
  }

  const resetForm = () => {
    setCategoryValue(undefined)
    setContentValue(null)
    setEsVideo(formData ? formData.categoria.nombre.toLowerCase() === 'video' : false)
    setRangeDate({
      startDate: moment(),
      endDate: null,
    })
    setIsEdit(false)
    setActiveTab(0)
    setCharacterCount(0)
    setCharacterCount2(0)
    setCharacterCount3(0)
    setContentValueItinerary('')
    setContentValueIncludes('')
    setContentValueHotels('')
    setContentValueRates('')
    setContentValueOutputs('')
    setBannerImagenes([])
    setOrdenSecciones(Object.values(TAB_ITEMS))
    setShowInDetail(false)
    reset();
  }

  const addImage = img => {
    setBannerImagenes(prevState => [...prevState, img])
  }

  const removeImageList = ({ target }) => {
    setBannerImagenes(bannerImagenes.filter(imageUrl => imageUrl !== target.id))
  }

  useEffect(() => {
    update()
  }, [])

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      resetForm()
      reset({})
    }
  }, [formState, reset])

  useEffect(() => {
    reset({})
    resetForm()
  }, [deleteBannerOk])

  useEffect(() => {
    if (categoryValue) {
      setIsSaveButton(false)
    } else {
      setIsSaveButton(true)
    }
  }, [categoryValue])

  useEffect(() => {
    const bannerVideo = items?.filter(
      i => i.id == categoryValue && i.categoriaUbicaciones[0].ubicacion.nombre == 'video',
    )
    if (bannerVideo?.length) {
      setEsVideo(false)
    } else {
      setEsVideo(true)
    }

    const bannerAnchoTotal = items?.filter(
      i => i.id == categoryValue && i.categoriaUbicaciones[0].ubicacion.nombre == 'banner-simple-ancho-total',
    )
    if (bannerAnchoTotal?.length) {
      setIsAnchoTotal(false)
    } else {
      setIsAnchoTotal(true)
    }

    const bannerX4 = items?.filter(
      i => i.id == categoryValue && i.categoriaUbicaciones[0].ubicacion.nombre == 'banner-hover-x4',
    )
    if (bannerX4?.length) {
      setIsBannerX4(false)
    } else {
      setIsBannerX4(true)
    }
  }, [categoryValue])

  useEffect(() => {
    if (formData) {
      setIsEdit(true)
      setActiveTab(0)
      setCategoryValue(formData.categoria.id || undefined)
      setRangeDate({
        startDate: moment(formData.fechaInicio),
        endDate: formData.fechaFin ? moment(formData.fechaFin) : undefined,
      })
      if (formData.detalle) {
        setContentValue(formData.detalle)
      }
      if (formData.itinerario) {
        setContentValueItinerary(formData.itinerario)
      }
      if (formData.incluye) {
        setContentValueIncludes(formData.incluye)
      }
      if (formData.hoteles) {
        setContentValueHotels(formData.hoteles)
      }
      if (formData.tarifas) {
        setContentValueRates(formData.tarifas)
      }
      if (formData.salida) {
        setContentValueOutputs(formData.salida)
      }
      if (formData.bannerImagenes) {
        setBannerImagenes(formData.bannerImagenes)
      }
      if (formData.ordenSecciones) {
        setOrdenSecciones(formData.ordenSecciones)
      }

      setShowInDetail(formData.imageDescriptionBanner)

      reset(formData)
    }
  }, [formData])

  const handleChange = () => {
    setShowInDetail(!showInDetail)
  }


  return (
    <form
      as='form'
      onSubmit={handleSubmit(d =>
        handleFormSubmit({
          ...d,
          endDate,
          startDate,
          detalle: contentValue !== '<p><br></p>' ? contentValue : null,
          itinerario: contentValueItinerary !== '<p><br></p>' ? contentValueItinerary : null,
          incluye: contentValueIncludes !== '<p><br></p>' ? contentValueIncludes : null,
          hoteles: contentValueHotels !== '<p><br></p>' ? contentValueHotels : null,
          tarifas: contentValueRates !== '<p><br></p>' ? contentValueRates : null,
          salida: contentValueOutputs !== '<p><br></p>' ? contentValueOutputs : null,
          imageDescriptionBanner: showInDetail
        }),
      )}
      {...restProps}
    >
      <Tabs index={activeTab} onChange={index => setActiveTab(index)}>
        <TabList>
          <Tab _selected={{ color: 'white', bg: 'blue.500' }}>{t('banners:principal')}</Tab>
          <Tab isDisabled={!isBannerX4 || !isAnchoTotal || !esVideo} _selected={{ color: 'white', bg: 'blue.500' }}>
            {t('banners:images')}
          </Tab>
          <Tab isDisabled={!isBannerX4 || !isAnchoTotal || !esVideo} _selected={{ color: 'white', bg: 'blue.500' }}>
            {t('banners:itinerary')}
          </Tab>
          <Tab isDisabled={!isBannerX4 || !isAnchoTotal || !esVideo} _selected={{ color: 'white', bg: 'blue.500' }}>
            {t('banners:includes')}
          </Tab>
          <Tab isDisabled={!isBannerX4 || !isAnchoTotal || !esVideo} _selected={{ color: 'white', bg: 'blue.500' }}>
            {t('banners:hotels')}
          </Tab>
          <Tab isDisabled={!isBannerX4 || !isAnchoTotal || !esVideo} _selected={{ color: 'white', bg: 'blue.500' }}>
            {t('banners:rates')}
          </Tab>
          <Tab isDisabled={!isBannerX4 || !isAnchoTotal || !esVideo} _selected={{ color: 'white', bg: 'blue.500' }}>
            {t('banners:outputs')}
          </Tab>
          <Tab isDisabled={!isBannerX4 || !isAnchoTotal || !esVideo} _selected={{ color: 'white', bg: 'blue.500' }}>
            {t('banners:detail')}
          </Tab>
          <Tab isDisabled={!isBannerX4 || !isAnchoTotal || !esVideo} _selected={{ color: 'white', bg: 'blue.500' }}>
            {t('forms:order')}
          </Tab>
        </TabList>

        <TabPanels mt='10'>
          <TabPanel>
            <Grid minHeight='400px' templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={4}>
              {/* Categoria */}
              <Box>
                <FormLabel isRequired>{t('forms:category')}</FormLabel>
                <Controller
                  isRequired
                  control={control}
                  name='categoria'
                  render={({ onChange, value, name }) => (
                    <SelectAsync
                      onChange={e => {
                        setCategoryValue(e.target.value)
                        onChange(e.target.value)
                      }}
                      placeholder={`${t('forms:selectOne')} ${t('forms:category')}`}
                      name={name}
                      value={categoryValue}
                      isLoading={isLoading}
                    >
                      {items &&
                        items.map(category => {
                          return (
                            <option key={category.id} value={category.id}>
                              {category.nombre}
                            </option>
                          )
                        })}
                    </SelectAsync>
                  )}
                />
              </Box>

              {/* Titulo */}
              <Box alignItems='center'>
                <FormLabel mr='1'>{t('forms:title')}</FormLabel>
                <FormInput
                  name='titulo'
                  isDisabled={!esVideo}
                  inputProps={{
                    type: 'text',
                    placeholder: `${t('forms:title')}`,
                    ref: register,
                  }}
                />
              </Box>

              {/* Descripcion */}
              <Box alignItems='center'>
                <FormLabel mr='1'>{t('forms:description')}</FormLabel>
                <FormInput
                  name='descripcion'
                  isDisabled={!esVideo}
                  inputProps={{
                    type: 'text',
                    placeholder: `${t('forms:description')}`,
                    ref: register,
                  }}
                />
              </Box>

              {/* Extra */}
              <Box alignItems='center'>
                <FormLabel mr='1'>Tag 1</FormLabel>
                <FormInput
                  name='extra'
                  isDisabled={!isBannerX4 || !esVideo || !isAnchoTotal}
                  inputProps={{
                    type: 'text',
                    placeholder: `Tag 1`,
                    ref: register,
                    maxLength: maxCharacterCount,
                    onChange: e => {
                      const newValue = e.target.value
                      if (newValue.length <= maxCharacterCount) {
                        setCharacterCount(newValue.length)
                      }
                    },
                  }}
                />
                <div className={characterCount === maxCharacterCount ? 'red-text' : ''} style={{ fontSize: '12px' }}>
                  Characters: {characterCount}/{maxCharacterCount}
                </div>
              </Box>

              {/* Precio */}
              <Box alignItems='center'>
                <FormLabel mr='1'>Tag 2</FormLabel>
                <FormInput
                  name='precio'
                  isDisabled={!isBannerX4 || !esVideo || !isAnchoTotal}
                  inputProps={{
                    type: 'text',
                    placeholder: `Tag 2`,
                    ref: register,
                    maxLength: maxCharacterCount,
                    onChange: e => {
                      const newValue = e.target.value
                      if (newValue.length <= maxCharacterCount) {
                        setCharacterCount2(newValue.length)
                      }
                    },
                  }}
                />
                <div className={characterCount2 === maxCharacterCount ? 'red-text' : ''} style={{ fontSize: '12px' }}>
                  Characters: {characterCount2}/{maxCharacterCount}
                </div>
              </Box>

              {/* Descuento */}
              <Box alignItems='center'>
                <FormLabel mr='1'>Tag 3</FormLabel>
                <FormInput
                  name='descuento'
                  isDisabled={!isBannerX4 || !esVideo || !isAnchoTotal}
                  inputProps={{
                    type: 'text',
                    placeholder: `Tag 3`,
                    ref: register,
                    maxLength: maxCharacterCount,
                    onChange: e => {
                      const newValue = e.target.value
                      if (newValue.length <= maxCharacterCount) {
                        setCharacterCount3(newValue.length)
                      }
                    },
                  }}
                />
                <div className={characterCount3 === maxCharacterCount ? 'red-text' : ''} style={{ fontSize: '12px' }}>
                  Characters: {characterCount3}/{maxCharacterCount}
                </div>
              </Box>

              <Box alignItems='center'>
                <Box d="flex" justifyContent="space-between" alignSelf="end">
                  <FormLabel>{t('forms:image')}</FormLabel>

                  <Box d="flex" justifyContent="center" alignItems="center">
                    <FormLabel pb='unset'>{t('forms:showInDetail')}</FormLabel>
                    {/* Checkbox para mostrar en detalle la imagen */}
                    <Checkbox isChecked={showInDetail} size="md" onChange={handleChange}>
                    </Checkbox>
                  </Box>
                </Box>

                {/* imagen URL */}
                <ImageInput
                  name='imagen'
                  isDisabled={!esVideo}
                  isRequired={esVideo}
                  setValue={setValue}
                  inputProps={{
                    placeholder: `URL ${t('forms:image')}`,
                    ref: register,
                    type: 'text',
                  }}
                />
              </Box>

              {/* Vigencia (Data Picker) */}
              <Box alignItems='center'>
                <FormLabel>{t('forms:validity')}</FormLabel>
                <Box>
                  <DateRangePicker
                    startDate={startDate}
                    startDateId='fecha_inicio'
                    endDate={endDate}
                    endDateId='fecha_fin'
                    onDatesChange={setRangePicker}
                    focusedInput={focusedInput}
                    onFocusChange={setFocusedPicker}
                    small
                    orientation='horizontal'
                    displayFormat='DD/MM/YYYY'
                    startDatePlaceholderText={t('common:startDate')}
                    endDatePlaceholderText={t('common:endDate')}
                  />
                </Box>
              </Box>

              {/* Orden */}
              <Box alignItems='center'>
                <FormLabel mr='1'>{t('forms:order')}</FormLabel>
                <Box>
                  <FormInput
                    name='orden'
                    inputProps={{
                      type: 'number',
                      placeholder: `${t('forms:order')}`,
                      ref: register,
                    }}
                  />
                </Box>
              </Box>

              {/* Link */}
              <Box alignItems='center'>
                <FormLabel mr='1' isRequired={!esVideo}>
                  {t('forms:Link')}
                </FormLabel>
                <FormInput
                  name='url'
                  isDisabled={esVideo && isAnchoTotal && isBannerX4}
                  isRequired={!esVideo}
                  inputProps={{
                    type: 'text',
                    placeholder: `${t('forms:Link')}`,
                    ref: register,
                  }}
                />
              </Box>

              {/* Proveedor */}
              {userRole === 2 && (
                <Box alignItems='center'>
                  <FormLabel mr='1'>{t('forms:provider')}</FormLabel>
                  <FormInput
                    name='proveedor'
                    inputProps={{
                      type: 'text',
                      placeholder: `${t('forms:provider')}`,
                      ref: register,
                    }}
                  />
                </Box>
              )}
            </Grid>
          </TabPanel>
          <TabPanel style={{ height: '400px', display: 'flex', flexDirection: 'column' }}>
            <Box alignItems='center' mb={4}>
              <Controller
                name='bannerImages'
                control={control}
                render={() => (
                  <>
                    <ImageInputV2
                      buttonDisabled={bannerImagenes.length >= 4}
                      name='bannerImages'
                      label={t('forms:image')}
                      setValue={(_name, url) => {
                        addImage(url)
                      }}
                      value={image || undefined}
                      onChange={value => setImage(value)}
                      inputProps={{
                        placeholder: `URL ${t('forms:image')}`,
                        ref: register,
                        type: 'text',
                      }}
                    />
                    <Box pt={4} style={{ display: 'flex', gap: '20px' }}>
                      {bannerImagenes?.map(bannerImagen => (
                        <Box key={bannerImagen} style={{ position: 'relative', width: '120px' }}>
                          <Image size='100px' objectFit='cover' src={bannerImagen} style={{ position: 'relative' }} />
                          <Button
                            id={bannerImagen}
                            style={{
                              position: 'absolute',
                              left: '80px',
                              top: '0.25rem',
                              zIndex: 1,
                              padding: '0.25rem',
                              opacity: '0.8',
                            }}
                            onClick={removeImageList}
                          >
                            <IoMdTrash style={{ pointerEvents: 'none' }} onClick={e => e.stopPropagation()} />
                          </Button>
                        </Box>
                      ))}
                    </Box>
                  </>
                )}
              />
            </Box>
          </TabPanel>
          <TabPanel style={{ height: '540px', display: 'flex', flexDirection: 'column' }}>
            <Grid>
              <ReactQuill
                style={{ height: '450px', marginBottom: '55px' }}
                className=''
                modules={{ toolbar: customToolbar }}
                value={contentValueItinerary}
                onChange={setContentValueItinerary}
                theme='snow'
              />
            </Grid>
          </TabPanel>
          <TabPanel style={{ height: '540px', display: 'flex', flexDirection: 'column' }}>
            <Grid>
              <ReactQuill
                style={{ height: '450px', marginBottom: '55px' }}
                className=''
                modules={{ toolbar: customToolbar }}
                value={contentValueIncludes}
                onChange={setContentValueIncludes}
                theme='snow'
              />
            </Grid>
          </TabPanel>
          <TabPanel style={{ height: '540px', display: 'flex', flexDirection: 'column' }}>
            <Grid>
              <ReactQuill
                style={{ height: '450px', marginBottom: '55px' }}
                className=''
                modules={{ toolbar: customToolbar }}
                value={contentValueHotels}
                onChange={setContentValueHotels}
                theme='snow'
              />
            </Grid>
          </TabPanel>
          <TabPanel style={{ height: '540px', display: 'flex', flexDirection: 'column' }}>
            <Grid>
              <ReactQuill
                style={{ height: '450px', marginBottom: '55px' }}
                className=''
                modules={{ toolbar: customToolbar }}
                value={contentValueRates}
                onChange={setContentValueRates}
                theme='snow'
              />
            </Grid>
          </TabPanel>
          <TabPanel style={{ height: '540px', display: 'flex', flexDirection: 'column' }}>
            <Grid>
              <ReactQuill
                style={{ height: '450px', marginBottom: '55px' }}
                className=''
                modules={{ toolbar: customToolbar }}
                value={contentValueOutputs}
                onChange={setContentValueOutputs}
                theme='snow'
              />
            </Grid>
          </TabPanel>
          <TabPanel style={{ height: '540px', display: 'flex', flexDirection: 'column' }}>
            <Grid>
              <ReactQuill
                style={{ height: '450px', marginBottom: '55px' }}
                className=''
                modules={{ toolbar: customToolbar }}
                value={contentValue}
                onChange={setContentValue}
                theme='snow'
              />
            </Grid>
          </TabPanel>
          <TabPanel style={{ height: '540px', display: 'flex', flexDirection: 'column' }}>
            <Grid>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='droppable'>
                  {provided => (
                    <div {...provided.droppableProps} ref={provided.innerRef} className='tabs-menu'>
                      {ordenSecciones.map((item, index) => (
                        <Draggable key={item} draggableId={item} index={index}>
                          {provided => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className='tabs-menu__tab'
                            >
                              {t(`forms:${item}`)}
                            </div>
                          )}
                        </Draggable>
                      ))}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Grid>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Box style={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
        <Button variantColor='blue' variant='outline' type='submit' isDisabled={isSaveButton}>
          {t('common:save')}
        </Button>
        {isEdit && (
          <Button onClick={handleCancel} variantColor='red' variant='outline'>
            {t('common:cancel')}
          </Button>
        )}
      </Box>
    </form>
  )
}
