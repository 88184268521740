import React, { useState } from 'react'
import '../styles/navBar/navBar.css'
import {
  Box,
  Flex,
  Link,
  Heading,
  Image,
  Menu,
  MenuButton,
  MenuList,
  Avatar,
  Text,
  Icon,
  MenuItem,
} from '@chakra-ui/core'
import { PageContainer } from '../common/helpers'
import { Link as RouterLink } from 'react-router-dom'
import { FaCog } from 'react-icons/fa'
import { GiHamburgerMenu } from 'react-icons/gi'
import { useTranslation } from 'react-i18next'
import '../../i18n'
import LogoTipoGeaEs from '../../static/img/logo-gea-es-neg.png'
import LogoTipoGeaPr from '../../static/img/logo-gea-pr.png'
import LogoGea from '../../static/img/logo-gea-new.png'
import { FaCaretDown } from 'react-icons/fa'
import { MenuItemAuthorized, NavItem, NavLink } from './menu/menu-elements'
import { handleLogout } from '../../helpers/commons'
import Can from '../../helpers/verify-permission'
import {
  APPS_LIST,
  MARCA_BLANCA_CRUD,
  SUPPLIERS_LIST,
  CUSTOM_SECTIONS_CREATE,
  DEFAULT_SECTIONS_CREATE,
  COMPANY_SECTIONS_CREATE,
  COMPANY_DETAIL_CREATE,
  LINK_TYPE_CREATE,
  CAPACITACIONES,
  COMUNICATIONS,
  COMUNICATIONS_NOTICIAS,
  COMUNICATIONS_GEA_IN_HOME,
  COMUNICATIONS_GEA_ACADEMY,
  COMUNICATIONS_EVENTOS,
  COMUNICATIONS_MKT,
  BANNERS_CREATE,
  SUPPLIERS_AGREEMENTS_VIEW,
  PACKAGE_CREATE,
  SUPPLIERS_LOGIN_COUNTER,
  SUPPLIERS_INCENTIVES_VIEW,
} from '../../helpers/permissions'
import { useSelector } from 'react-redux'
import { getUserApplicationId, getUserCountryId, getUserInformation, getUserRolId } from '../../redux/selectors'
import '../styles/common/custom-nav-menu-button.css'
import LanguageSelector from '../common/LanguageSelector'

export default function Navbar({ ...props }) {
  const { t, i18n } = useTranslation(['common', 'applications', 'exchange'])
  const userCountryId = useSelector(getUserCountryId)
  const appId = useSelector(getUserApplicationId)
  const [show, setShow] = useState(false)
  const handleToggle = () => setShow(!show)
  const logo = LogoGea
  const userRolId = useSelector(getUserRolId)
  const permisosUser = userRolId == 1 ? true : false
  let permisosAdminCountryAgencia = userRolId === 2 || userRolId === 4 ? true : false
  let permisosCountry = userCountryId === 2 || userCountryId === 3 ? false : true

  return (
    <Box
      style={{
        position: 'sticky',
        top: '-1px',
        zIndex: '250',
        backgroundColor: '#144DBE',
        padding: '8px 0px',
      }}
      as='nav'
      {...props}
    >
      <PageContainer>
        <Flex align='center' justify='space-between' wrap='wrap' py='0.8rem' color='white'>
          <Flex align='center' mr={5}>
            <Heading as='h1' size='lg' letterSpacing={'-.1rem'} marginRight='10px'>
              <Link as={RouterLink} to='/'>
                <Image src={logo} alt='GEA' h='65px' objectFit='contain' />
              </Link>
            </Heading>
          </Flex>
          <Box display={{ base: 'block', md: 'none' }} onClick={handleToggle}>
            <Box as={GiHamburgerMenu} />
          </Box>
          <Box
            display={{ sm: show ? 'block' : 'none', md: 'flex' }}
            width={{ sm: 'full', md: 'auto' }}
            alignItems='center'
            flexGrow={1}
          >
            <NavLink className='custom-nav-item' to='/'>
              {t('common:navbar.home')}
            </NavLink>

            <Can
              permission={[APPS_LIST, SUPPLIERS_LIST, SUPPLIERS_AGREEMENTS_VIEW, SUPPLIERS_LOGIN_COUNTER]}
              yes={() => (
                <Menu>
                  <MenuButton className='custom-nav-menu-button'>
                    <NavItem className='custom-nav-item'>{t('common:navbar.agreements')}</NavItem>
                  </MenuButton>
                  <MenuList placement='bottom-start' className='menuListNavBar'>
                    {permisosCountry ? (
                      <MenuItemAuthorized
                        className='menuItemNavBar'
                        permission={[APPS_LIST, SUPPLIERS_LIST, SUPPLIERS_AGREEMENTS_VIEW, SUPPLIERS_LOGIN_COUNTER]}
                        as={RouterLink}
                        to='/group'
                        onClick={handleToggle}
                      >
                        {t('common:navbar.group')}
                      </MenuItemAuthorized>
                    ) : (
                      ''
                    )}
                    <MenuItemAuthorized
                      className='menuItemNavBar'
                      permission={[APPS_LIST, SUPPLIERS_AGREEMENTS_VIEW]}
                      as={RouterLink}
                      to='/suppliers-agreements'
                      onClick={handleToggle}
                    >
                      {t('common:navbar.providers')}
                    </MenuItemAuthorized>
                    <MenuItemAuthorized
                      className='menuItemNavBar'
                      permission={[APPS_LIST, SUPPLIERS_INCENTIVES_VIEW]}
                      as={RouterLink}
                      to='/incentives'
                      onClick={handleToggle}
                    >
                      {t('common:navbar.incentives')}
                    </MenuItemAuthorized>
                    <MenuItemAuthorized
                      className='menuItemNavBar'
                      permission={SUPPLIERS_LOGIN_COUNTER}
                      as={RouterLink}
                      to='/suppliers-agreements'
                      onClick={handleToggle}
                    >
                      {' '}
                      {t('common:navbar.autoLogin')}
                    </MenuItemAuthorized>
                  </MenuList>
                </Menu>
              )}
            />

            {!permisosUser && (
              <Menu>
                <MenuButton className='custom-nav-menu-button'>
                  <NavItem className='custom-nav-item'>{t('common:navbar.administration')}</NavItem>
                </MenuButton>
                <MenuList placement='bottom-start' className='menuListNavBar'>
                  {userCountryId !== 3 ? (
                    <MenuItemAuthorized
                      className='menuItemNavBar'
                      permission={[COMUNICATIONS]}
                      as={RouterLink}
                      to='/travelgea'
                      onClick={handleToggle}
                    >
                      {t('common:navbar.travelgea')}
                    </MenuItemAuthorized>
                  ) : (
                    ''
                  )}
                  <MenuItemAuthorized
                    className='menuItemNavBar'
                    permission={[COMUNICATIONS]}
                    as={RouterLink}
                    to='/grupo-gea'
                    onClick={handleToggle}
                  >
                    {t('common:navbar.grupoGea')}
                  </MenuItemAuthorized>
                  {userCountryId === 3 || userCountryId === 2 ? (
                    ''
                  ) : (
                    <MenuItemAuthorized
                      className='menuItemNavBar'
                      permission={[COMUNICATIONS]}
                      as={RouterLink}
                      to='/exchange-type'
                      onClick={handleToggle}
                    >
                      {t('exchange:changeType')}
                    </MenuItemAuthorized>
                  )}
                </MenuList>
              </Menu>
            )}
            <Can
              permission={[CAPACITACIONES]}
              yes={() => (
                <Menu>
                  <MenuButton className='custom-nav-menu-button'>
                    <NavItem className='custom-nav-item'>{t('common:navbar.training')}</NavItem>
                  </MenuButton>
                  <MenuList className='menuListNavBar' placement='bottom-start'>
                    <MenuItemAuthorized
                      className='menuItemNavBar'
                      permission={COMUNICATIONS_EVENTOS}
                      as={RouterLink}
                      to={{
                        pathname: '/communications',
                        search: '?capacitations',
                      }}
                      onClick={handleToggle}
                    >
                      <p>{t('common:navbar.training')}</p>
                    </MenuItemAuthorized>
                    <MenuItemAuthorized
                      className='menuItemNavBar'
                      permission={COMUNICATIONS_GEA_IN_HOME}
                      as={RouterLink}
                      to={{
                        pathname: '/communications',
                        search: '?geaHome',
                      }}
                      onClick={handleToggle}
                    >
                      {t('common:navbar.geaHome')}
                    </MenuItemAuthorized>
                  </MenuList>
                </Menu>
              )}
            />

            <Can
              permission={[COMUNICATIONS]}
              yes={() => (
                <Menu>
                  <MenuButton className='custom-nav-menu-button'>
                    <NavItem className='custom-nav-item'>{t('common:navbar.communication')}</NavItem>
                  </MenuButton>
                  <MenuList className='menuListNavBar' placement='bottom-start'>
                    <MenuItemAuthorized
                      className='menuItemNavBar'
                      permission={COMUNICATIONS_NOTICIAS}
                      as={RouterLink}
                      to={{
                        pathname: '/communications',
                        search: '?news',
                      }}
                      onClick={handleToggle}
                    >
                      {t('common:navbar.news')}
                    </MenuItemAuthorized>
                    <MenuItemAuthorized
                      className='menuItemNavBar'
                      permission={COMUNICATIONS_EVENTOS}
                      as={RouterLink}
                      to={{
                        pathname: '/communications',
                        search: '?events',
                      }}
                      onClick={handleToggle}
                    >
                      {t('common:navbar.events')}
                    </MenuItemAuthorized>
                    {userCountryId !== 3 && (
                      <MenuItemAuthorized className='menuItemNavBar' permission={COMUNICATIONS_MKT}>
                        <a
                          href='https://drive.google.com/drive/folders/1i1TASY3CuZiSnq5LE1IbRkk_z6mEqdQb?usp=sharing'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {t('common:navbar.marketing')}
                        </a>
                      </MenuItemAuthorized>
                    )}
                  </MenuList>
                </Menu>
              )}
            />
            <Can
              permission={[APPS_LIST, SUPPLIERS_LIST]}
              yes={() => (
                <Menu>
                  <MenuButton className='custom-nav-menu-button'>
                    <NavItem className='custom-nav-item'>{t('common:navbar.manage')}</NavItem>
                  </MenuButton>
                  <MenuList placement='bottom-start' className='menuListNavBar'>
                    <MenuItemAuthorized
                      className='menuItemNavBar'
                      permission={APPS_LIST}
                      as={RouterLink}
                      to='/applications'
                      onClick={handleToggle}
                    >
                      {t('common:navbar.applications')}
                    </MenuItemAuthorized>
                    <MenuItemAuthorized
                      className='menuItemNavBar'
                      permission={SUPPLIERS_LIST}
                      as={RouterLink}
                      to='/suppliers'
                      onClick={handleToggle}
                    >
                      {t('common:navbar.providers')}
                    </MenuItemAuthorized>
                  </MenuList>
                </Menu>
              )}
            />
            {/* Nuevo Item Marca blanca */}
            <Can
              permission={[
                CUSTOM_SECTIONS_CREATE,
                DEFAULT_SECTIONS_CREATE,
                COMPANY_SECTIONS_CREATE,
                COMPANY_DETAIL_CREATE,
                LINK_TYPE_CREATE,
                BANNERS_CREATE,
                APPS_LIST,
                SUPPLIERS_LIST,
                PACKAGE_CREATE,
              ]}
              yes={() => (
                <Menu>
                  {/* Nuevo Item Marca blanca */}
                  <MenuButton className='custom-nav-menu-button'>
                    <NavItem className='custom-nav-item'>{t('common:navbar.white-brand')}</NavItem>
                  </MenuButton>
                  <MenuList className='menuListNavBar' placement='bottom-start'>
                    {!permisosUser && (
                      <MenuItemAuthorized
                        className='menuItemNavBar'
                        permission={BANNERS_CREATE}
                        as={RouterLink}
                        to='/banners'
                        onClick={handleToggle}
                      >
                        {t('common:navbar.banners')}
                      </MenuItemAuthorized>
                    )}
                    {!permisosUser ? (
                      <MenuItemAuthorized
                        className='menuItemNavBar'
                        permission={MARCA_BLANCA_CRUD}
                        as={RouterLink}
                        to='/consultations'
                        onClick={handleToggle}
                      >
                        {t('common:navbar.consultations')}
                      </MenuItemAuthorized>
                    ) : (
                      ''
                    )}
                    {!permisosUser && userCountryId === 2 ? (
                      <MenuItemAuthorized
                        className='menuItemNavBar'
                        permission={MARCA_BLANCA_CRUD}
                        as={RouterLink}
                        to='/claims'
                        onClick={handleToggle}
                      >
                        {t('common:navbar.claims')}
                      </MenuItemAuthorized>
                    ) : (
                      ''
                    )}
                    <MenuItemAuthorized
                      className='menuItemNavBar'
                      permission={MARCA_BLANCA_CRUD}
                      as={RouterLink}
                      to='/web-information'
                      onClick={handleToggle}
                    >
                      {t('common:navbar.web-information')}
                    </MenuItemAuthorized>

                    {!permisosUser && (
                      <MenuItemAuthorized
                        className='menuItemNavBar'
                        permission={MARCA_BLANCA_CRUD}
                        as={RouterLink}
                        to='/menu'
                        onClick={handleToggle}
                      >
                        {t('common:navbar.menu')}
                      </MenuItemAuthorized>
                    )}
                    <MenuItemAuthorized
                      className='menuItemNavBar'
                      permission={PACKAGE_CREATE}
                      as={RouterLink}
                      to='/currency/exchange'
                      onClick={handleToggle}
                    >
                      {t('common:navbar.currency')}
                    </MenuItemAuthorized>
                    {permisosUser || permisosAdminCountryAgencia ? (
                      <MenuItemAuthorized
                        className='menuItemNavBar'
                        permission={PACKAGE_CREATE}
                        as={RouterLink}
                        to='/packages/departures/listHotel'
                        onClick={handleToggle}
                      >
                        {t('common:navbar.packageHotels')}
                      </MenuItemAuthorized>
                    ) : (
                      ''
                    )}
                    {permisosUser || userRolId === 4 ? (
                      <MenuItemAuthorized
                        className='menuItemNavBar'
                        permission={PACKAGE_CREATE}
                        as={RouterLink}
                        to='/packages'
                        onClick={handleToggle}
                      >
                        {t('common:navbar.packages')}
                      </MenuItemAuthorized>
                    ) : (
                      ''
                    )}
                    {permisosUser ? (
                      <MenuItemAuthorized
                        className='menuItemNavBar'
                        permission={MARCA_BLANCA_CRUD}
                        as={RouterLink}
                        to='/bookings'
                        onClick={handleToggle}
                      >
                        {t('common:navbar.reservations')}
                      </MenuItemAuthorized>
                    ) : (
                      ''
                    )}
                    {!permisosUser && (
                      <MenuItemAuthorized
                        className='menuItemNavBar'
                        permission={MARCA_BLANCA_CRUD}
                        as={RouterLink}
                        to='/sections'
                        onClick={handleToggle}
                      >
                        {t('common:navbar.sections')}
                      </MenuItemAuthorized>
                    )}
                  </MenuList>
                </Menu>
              )}
            />
            <Can permission={[CUSTOM_SECTIONS_CREATE]} />

            <Can />
          </Box>
          <Box display='block' mr={{ base: 2, md: 4 }}>
            <LanguageSelector />
          </Box>
          <Box display='block' mt={{ base: 4, md: 0 }} width={{ base: 140 }}>
            <Flex
              direction='row'
              alignContent='center'
              justifyContent='flex-end'
              alignItems='center'
              px={{ base: '0px', md: 0 }}
            >
              <Menu>
                <MenuButton className='custom-nav-menu-button'>
                  <NavItem className='custom-nav-item'>{t('common:myAccount')}</NavItem>
                </MenuButton>
                <MenuList style={{ zIndex: '333', marginRight: '2rem', marginTop: '1rem' }}>
                  <Can
                    permission={[MARCA_BLANCA_CRUD]}
                    yes={() => (
                      <MenuItemAuthorized
                        permission={MARCA_BLANCA_CRUD}
                        as={RouterLink}
                        to={`/my-applications/edit/${appId}/my-account`}
                      >
                        <Icon name='settings' mr='5px' size='22px' color='blue.600' />{' '}
                        <Text color='blue.600'>{t('common:myAccountInformation')}</Text>
                      </MenuItemAuthorized>
                    )}
                  />
                  <MenuItem onClick={handleLogout} color='blue.600'>
                    <Icon name='small-close' mr='5px' size='22px' /> {t('common:logout')}
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          </Box>
        </Flex>
      </PageContainer>
    </Box>
  )
}
