import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { getRoles, updateUser } from '../../../api/application'
import { Stack, Box, FormLabel, Select, SimpleGrid, Button, useToast, useDisclosure, Spinner } from '@chakra-ui/core'
import FormInput from '../form-input'
import { useTranslation } from 'react-i18next'
import { toastCustomizedError, toastCustomizedSuccess } from '../../common/toast-default-config'
import { useSelector } from 'react-redux'
import { getToken, getUserRolId } from '../../../redux/selectors'
import ModalSimple from '../../common/modal-simple'
import { IconButton, InputAdornment, TextField } from '@material-ui/core'
import { MdVisibility, MdVisibilityOff } from 'react-icons/md'
import '../../styles/common/common.css'
import { handleLogout } from '../../../helpers/commons'

export default function UserForm({ onSubmit, formData = false, buttonLabel = false, isMyAccount }) {
  const { t } = useTranslation(['forms', 'applications', 'login'])
  const [isLoading, setIsLoading] = useState(false)
  const initialRolesState = {
    data: null,
    isLoading: true,
    error: null,
  }
  const [roles, setRoles] = useState(initialRolesState)
  const [submitBlock, setSubmitBlock] = useState(false)
  const [message, setMessage] = useState('')
  const token = useSelector(getToken)
  const toast = useToast()
  const [newPassword, setNewPassword] = useState(undefined)
  const modalEditDisclosure = useDisclosure()
  const modalDisclosure = useDisclosure()
  const userRolId = useSelector(getUserRolId)
  const permisosUser = userRolId === 1 ? true : false
  const permisosUserCountry = userRolId === 2 ? true : false
  const permisosUserManager = userRolId === 3 ? true : false
  const [inputType, setInputType] = useState('password')
  const { handleSubmit, register, reset } = useForm({
    defaultValues: formData,
  })
  const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d]{8,}$/

  const handlePasswordUser = async () => {
    setIsLoading(true)
    try {
      await updateUser(formData.id, { password: newPassword })
      toast(toastCustomizedSuccess(t('forms:actionCompleted')))
      modalEditDisclosure.onClose()
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      alert('Error getting data')
    }
  }

  const rolesSetter = async () => {
    setRoles(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const response = await getRoles(token)
      let roles = response.data
      if (permisosUserManager) {
        roles = response.data.filter(item => item.id !== 1 && item.id !== 2)
      }else if(permisosUserCountry){
        roles = response.data.filter(item => item.id !== 1)
      }
      setRoles({
        data: roles,
        isLoading: false,
        error: null,
      })
    } catch (error) {
      setRoles(state => ({
        ...state,
        error: error,
      }))
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  useEffect(() => {
    rolesSetter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const submit = async data => {
    setSubmitBlock(true)
    await onSubmit({ ...data, password: newPassword })
    setSubmitBlock(false)
  }

  const userNameString = `${t('forms:name')}_${t('applications:user')}`

  useEffect(() => {
    if (!regex.test(newPassword) && !formData) {
      setMessage(t('forms:passwordValidation'))
    } else if (!regex.test(newPassword) && formData && isMyAccount) {
      setMessage(t('forms:passwordValidation'))
    } else {
      setMessage('')
    }
  }, [newPassword])

  return (
    <form autoComplete='off' onSubmit={handleSubmit(submit)}>
      {!isMyAccount ? (
        <Box style={{ display: 'flex', flexDirection: 'column' }}>
          <Stack autoComplete='off'>
            <SimpleGrid autoComplete='off' columns={{ base: 1, md: 2 }} spacing={2}>
              <FormInput
                isRequired
                name='username'
                label={`${t('applications:user')}`}
                inputProps={{
                  placeholder: userNameString,
                  ref: register,
                  autoComplete: 'new-text',
                }}
              />

              {!formData ? (
                <Box>
                  <FormLabel htmlFor='rol.id' isRequired>
                    {t('login:password')}
                  </FormLabel>
                  <TextField
                    onChange={e => setNewPassword(e.target.value)}
                    type='text'
                    variant='outlined'
                    id='password'
                    size='small'
                    name='password'
                    style={{ borderColor: '#E2E8F0' }}
                    InputProps={{
                      ref: register,
                      style: {
                        fontFamily: inputType,
                      },
                      endAdornment: (
                        <InputAdornment className='hide-password' position='end' style={{ marginLeft: '6rem' }}>
                          <IconButton
                            onClick={() =>
                              inputType === 'password' ? setInputType(`'Nunito', sans-serif`) : setInputType('password')
                            }
                          >
                            {inputType === 'password' ? (
                              <MdVisibility style={{ color: '#1f418f' }} />
                            ) : (
                              <MdVisibilityOff style={{ color: '#1f418f' }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  ></TextField>
                </Box>
              ) : (
                <FormInput
                  isRequired={formData === false}
                  isDisabled={formData}
                  name='password'
                  label={t('login:password')}
                  inputProps={{
                    type: 'text',
                    placeholder: '********',
                    autoComplete: 'new-password',
                  }}
                />
              )}
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={2}>
              <FormInput
                isRequired
                name='nombre'
                label={t('forms:name')}
                inputProps={{
                  placeholder: t('forms:name'),
                  ref: register,
                }}
              />
              <FormInput
                isRequired
                name='apellido'
                label={t('forms:lastname')}
                inputProps={{
                  placeholder: t('forms:lastname'),
                  ref: register,
                }}
              />
            </SimpleGrid>
            <Box>
              <FormLabel htmlFor='rol.id' isRequired>
                {t('common:rol')}
              </FormLabel>
              {!roles.isLoading && roles.data !== null ? (
                <Select isRequired placeholder={t('forms:selectOption')} name='rol.id' ref={register}>
                  {roles.data.map(rol => (
                    <option key={rol.id} value={rol.id}>
                      {rol.name}
                    </option>
                  ))}
                </Select>
              ) : (
                <Select placeholder={`${t('forms:loading')}...`}></Select>
              )}
            </Box>
            <FormInput
              isRequired
              name='email'
              label='Email'
              inputProps={{
                type: 'email',
                placeholder: '@',
                ref: register,
              }}
            />
          </Stack>

          {message && newPassword && !formData && (
            <Box
              style={{
                marginTop: '1rem',
                display: 'flex',
                flexDirection: 'column',
                gap: '.25rem',
                fontWeight: '600',
                justifyContent: 'center',
                border: '1px solid #E53E3B',
                color: '#E53E3B',
                fontSize: '.85rem',
                padding: '.5rem',
                width: '95%',
                borderRadius: '5px',
                alignSelf: 'center',
              }}
            >
              <Box>{t('forms:passwordValidation')}</Box>
              <Box>{t('forms:specialCh')}</Box>
            </Box>
          )}

          <Button
            style={{ alignSelf: 'self-end' }}
            mt={6}
            variantColor='blue'
            variant='outline'
            isDisabled={message}
            type='submit'
            isLoading={submitBlock}
          >
            {buttonLabel || t('common:create')}
          </Button>
        </Box>
      ) : (
        <Stack autoComplete='off'>
          {isLoading ? (
            <Box px='5' py='10' textAlign='center'>
              <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
            </Box>
          ) : (
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1.5rem',
                justifyContent: 'center',
                padding: '0rem 1rem',
                minWidth: '30rem',
              }}
            >
              <FormInput
                isDisabled
                name='id'
                label='ID'
                inputProps={{
                  placeholder: t('forms:id'),
                  ref: register,
                }}
              />
              <FormInput
                isDisabled
                name='username'
                label={t('applications:user')}
                inputProps={{
                  placeholder: t('forms:username'),
                  ref: register,
                }}
              />
              <Box>
                <FormLabel htmlFor='rol.id'>{t('common:rol')}</FormLabel>
                <Select isDisabled>
                  <option key={formData.rol.id} value={formData.rol.id}>
                    {formData.rol.name}
                  </option>
                </Select>
              </Box>
              <FormInput
                name='nombre'
                isRequired
                label={t('forms:name')}
                inputProps={{
                  placeholder: t('forms:name'),
                  ref: register,
                }}
              />
              <FormInput
                name='apellido'
                isRequired
                label={t('forms:lastname')}
                inputProps={{
                  placeholder: t('forms:lastname'),
                  ref: register,
                }}
              />
              <FormInput
                name='email'
                isRequired
                type='email'
                label='Email'
                inputProps={{
                  type: 'email',
                  placeholder: '@',
                  ref: register,
                }}
              />
              <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '1rem' }}>
                <Button mt={6} onClick={() => modalEditDisclosure.onOpen()} variantColor='blue' variant='outline'>
                {t('common:reset_password')}
                </Button>

                <Button
                  mt={6}
                  onClick={() => {
                    reset(formData)
                  }}
                  variantColor='red'
                  variant='outline'
                >
                  {t('common:cancel')}
                </Button>

                <Button mt={6} variantColor='blue' variant='outline' type='submit' isLoading={submitBlock}>
                  {buttonLabel}
                </Button>
              </Box>
            </Box>
          )}

          <ModalSimple
            style={{ borderRadius: '5px' }}
            {...modalEditDisclosure}
            onClose={() => {
              modalEditDisclosure.onClose()
              setMessage('')
            }}
            size='400px'
            title={`${t('common:edit')} ${t('login:password').toLowerCase()}`}
          >
            {isLoading ? (
              <Box px='5' py='10' textAlign='center'>
                <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
              </Box>
            ) : (
              <Box style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
                <b>{formData && `${formData.username} (#${formData.id})`}</b>
                <TextField
                  onChange={e => setNewPassword(e.target.value)}
                  label={t('login:change_password')}
                  type='text'
                  variant='outlined'
                  id='password'
                  InputProps={{
                    style: {
                      fontFamily: inputType,
                    },
                    autoComplete: 'off',
                    endAdornment: (
                      <InputAdornment className='hide-password' position='end' style={{ marginLeft: '6rem' }}>
                        <IconButton
                          onClick={() =>
                            inputType === 'password' ? setInputType(`'Nunito', sans-serif`) : setInputType('password')
                          }
                        >
                          {inputType === 'password' ? (
                            <MdVisibility style={{ color: '#1f418f' }} />
                          ) : (
                            <MdVisibilityOff style={{ color: '#1f418f' }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
                {message && newPassword && (
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '.25rem',
                      fontWeight: '600',
                      justifyContent: 'center',
                      border: '1px solid #E53E3B',
                      color: '#E53E3B',
                      fontSize: '.85rem',
                      padding: '.5rem',
                      width: '95%',
                      borderRadius: '5px',
                      alignSelf: 'center',
                    }}
                  >
                    <Box>{t('forms:passwordValidation')}</Box>
                    <Box>{t('forms:specialCh')}</Box>
                  </Box>
                )}
                <Box style={{ display: 'flex', gap: '1rem', justifyContent: 'center', marginTop: '1.5rem' }}>
                  <Button
                    onClick={() => {
                      modalEditDisclosure.onClose()
                      setNewPassword('')
                      setMessage('')
                    }}
                    variantColor='red'
                    variant='outline'
                  >
                    {t('common:cancel')}
                  </Button>
                  <Button
                    isDisabled={message || !newPassword}
                    variantColor='blue'
                    variant='outline'
                    type='submit'
                    onClick={() => {
                      modalEditDisclosure.onClose()
                      modalDisclosure.onOpen()
                    }}
                  >
                    {t('forms:save')}
                  </Button>
                </Box>
              </Box>
            )}
          </ModalSimple>

          <ModalSimple
            style={{ borderRadius: '5px' }}
            {...modalDisclosure}
            size='400px'
            title={`${t('common:edit')} ${t('login:password').toLowerCase()}`}
          >
            {isLoading ? (
              <Box px='5' py='10' textAlign='center'>
                <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
              </Box>
            ) : (
              <Box style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
                <b>{formData && `${formData.username} (#${formData.id})`}</b>

                <Box px='5' py='5' textAlign='center'>
                  <p>{t('forms:closeSession')}</p>
                </Box>
                <Box style={{ display: 'flex', gap: '1rem', justifyContent: 'center', marginTop: '1.5rem' }}>
                  <Button
                    variantColor='red'
                    variant='outline'
                    onClick={() => {
                      modalDisclosure.onClose()
                      setNewPassword('')
                      setMessage('')
                    }}
                  >
                    {t('common:cancel')}
                  </Button>
                  <Button
                    variantColor='blue'
                    variant='outline'
                    type='submit'
                    onClick={() => {
                      handlePasswordUser()
                      handleLogout()
                      modalDisclosure.onClose()
                    }}
                  >
                    {t('common:continue')}
                  </Button>
                </Box>
              </Box>
            )}
          </ModalSimple>
        </Stack>
      )}
    </form>
  )
}
