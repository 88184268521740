import axios from 'axios'
import { getStorageToken } from '../helpers/utils'
import { configWithParams } from './intranet'

const { REACT_APP_MARCABLANCA_API_V2, REACT_APP_GEO_API_V2 } = process.env

export const config = {
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
  },
}

export const configWithToken = (authToken) => {
  const tokenAux = getStorageToken()
  return ({
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
    ...(tokenAux ? { Authorization: `Bearer ${tokenAux}` } : {}),
  },
})}

// Instances

let baseURLsection = REACT_APP_MARCABLANCA_API_V2
export const webInformationInstance = axios.create({
  baseURL: baseURLsection,
})

// Endpoints

export const getAllCountriesEndpoint = '/api/pais'

export const getCompanyDetailsEndpoint = (appId) => (appId ? `/api/empresa-detalle?empresaId=${appId}` : '/api/empresa-detalle')

export const deleteCompanyDetailsEndpoint = (id) => `/api/empresa-detalle/${id}`

export const getLinkTypesEndpoint = (clue) => (clue ? `/api/tipo-enlace?buscar=${clue}` : '/api/tipo-enlace')

export const getCategoryDetailsEndpoint = (clue) => (clue ? `/api/categoria-detalle?buscar=${clue}` : '/api/categoria-detalle')

export const getAllCategoryDetailsEndpoint = '/api/categoria-detalle/all'

export const createCompanyDetailEndpoint = '/api/empresa-detalle'

export const getAllCompanyDetailsEndpoint = '/api/empresa-detalle/all'

export const createTypesEndpoint = '/api/tipo-enlace'

export const getAllTypesEndpoint = '/api/tipo-enlace/all'

export const createCategoryEndpoint = '/api/categoria-detalle'

export const updateDefaultSectionEndpoint = (sectionId) => `/api/secciones-default/${sectionId}`

export const updateLinkTypeEndpoint = (sectionId) => `/api/tipo-enlace/${sectionId}`

export const updateCategoryEndpoint = (sectionId) => `/api/categoria-detalle/${sectionId}`

export const updateCustomSectionEndpoint = (sectionId) => `/api/secciones-custom/${sectionId}`

export const updateCompanyDetailEndpoint = (sectionId) => `/api/empresa-detalle/${sectionId}`

export const getCompDetailByIdEndpoint = (compDetailId) => `/api/empresa-detalle/${compDetailId}`

export const getLinkTypeByIdEndpoint = (compDetailId) => `/api/tipo-enlace/${compDetailId}`

export const getCategoryByIdEndpoint = (compDetailId) => `/api/categoria-detalle/${compDetailId}`

export const getCompanyDetailTypes = (userCountryId, lenguage) => userCountryId? `/api/tipo-enlace?paisId=${userCountryId}&lenguage=${lenguage}` : '/api/tipo-enlace'

// Requests

export const getAllCountries = (authToken) =>
  axios.get(`${REACT_APP_GEO_API_V2}${getAllCountriesEndpoint}`, configWithToken(authToken))

export const getCompanyDetails = (appId, authToken) =>
  axios.get(`${REACT_APP_MARCABLANCA_API_V2}${getCompanyDetailsEndpoint(appId)}`, configWithToken(authToken))

export const getAllCompanyDetails = (data) =>
  axios.get(`${REACT_APP_MARCABLANCA_API_V2}${getAllCompanyDetailsEndpoint}`, configWithParams(data))

export const deleteCompanyDetails = (id, authToken) =>
  axios.delete(`${REACT_APP_MARCABLANCA_API_V2}${deleteCompanyDetailsEndpoint(id)}`, configWithToken(authToken)) //-- //-- //-- //-- //-- //--

export const getLinkTypes = (data) =>
  axios.get(`${REACT_APP_MARCABLANCA_API_V2}${createTypesEndpoint}`, configWithParams(data))

export const getAllLinkTypes = (data) =>
  axios.get(`${REACT_APP_MARCABLANCA_API_V2}${getAllTypesEndpoint}`, configWithParams(data))

export const deleteTipoEnlace = (id, authToken) =>
  axios.delete(`${REACT_APP_MARCABLANCA_API_V2}${updateLinkTypeEndpoint(id)}`, configWithToken(authToken))

export const getCategoryDetails = (authToken, clue) =>
  axios.get(`${REACT_APP_MARCABLANCA_API_V2}${getCategoryDetailsEndpoint(clue)}`, configWithToken(authToken))

export const getAllCategoryDetails = (data) =>
  axios.get(`${REACT_APP_MARCABLANCA_API_V2}${getAllCategoryDetailsEndpoint}`, configWithParams(data))

export const deleteCategoryDetails = (id, authToken) =>
  axios.delete(`${REACT_APP_MARCABLANCA_API_V2}${updateCategoryEndpoint(id)}`, configWithToken(authToken))

export const createCompanyDetail = (data, authToken) =>
  axios.post(`${REACT_APP_MARCABLANCA_API_V2}${createCompanyDetailEndpoint}`, data, configWithToken(authToken))

export const createTypes = (data, authToken) =>
  axios.post(`${REACT_APP_MARCABLANCA_API_V2}${createTypesEndpoint}`, data, configWithToken(authToken))

export const createCategory = (data, authToken) =>
  axios.post(`${REACT_APP_MARCABLANCA_API_V2}${createCategoryEndpoint}`, data, configWithToken(authToken))

export const UpdateDefaultSection = (sectionId, data, authToken) =>
  axios.put(`${REACT_APP_MARCABLANCA_API_V2}${updateDefaultSectionEndpoint(sectionId)}`, data, configWithToken(authToken))

export const updateLinkType = (sectionId, data, authToken) =>
  axios.put(`${REACT_APP_MARCABLANCA_API_V2}${updateLinkTypeEndpoint(sectionId)}`, data, configWithToken(authToken))

export const UpdateCategory = (sectionId, data, authToken) =>
  axios.put(`${REACT_APP_MARCABLANCA_API_V2}${updateCategoryEndpoint(sectionId)}`, data, configWithToken(authToken))

export const UpdateCustomSection = (sectionId, data, authToken) =>
  axios.put(`${REACT_APP_MARCABLANCA_API_V2}${updateCustomSectionEndpoint(sectionId)}`, data, configWithToken(authToken))

export const UpdateCompanyDetail = (sectionId, data, authToken) =>
  axios.put(`${REACT_APP_MARCABLANCA_API_V2}${updateCompanyDetailEndpoint(sectionId)}`, data, configWithToken(authToken))

export const getCompDetailById = (compDetailId, authToken) =>
  axios.get(`${REACT_APP_MARCABLANCA_API_V2}${getCompDetailByIdEndpoint(compDetailId)}`, configWithToken(authToken))

export const getLinkTypeById = (id, authToken) =>
  axios.get(`${REACT_APP_MARCABLANCA_API_V2}${getLinkTypeByIdEndpoint(id)}`, configWithToken(authToken))

export const getCategoryById = (categoryId, authToken) =>
  axios.get(`${REACT_APP_MARCABLANCA_API_V2}${getCategoryByIdEndpoint(categoryId)}`, configWithToken(authToken))

export const getCompanyTypes = (userCountryId, lenguage, authToken) =>
  axios.get(`${REACT_APP_MARCABLANCA_API_V2}${getCompanyDetailTypes(userCountryId, lenguage)}`, configWithToken(authToken))
