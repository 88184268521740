import React, { useMemo, useState, useEffect, useCallback, useContext } from 'react'
import { useDisclosure, Stack, Box, Spinner, Text, useToast } from '@chakra-ui/core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  createCompanyDetail,
  deleteCompanyDetails,
  getLinkTypes,
  getAllCompanyDetails,
} from '../../../../api/web-information'
import { getToken, getAuthToken, getUserApplicationId } from '../../../../redux/selectors'
import { ButtonEdit, ButtonDelete } from '../../../forms/buttons/edit'
import Table from '../../../common/table'
import { Panel } from '../../../common/helpers'
import {
  toastSuccess,
  toastCustomizedError,
  toastCustomizedSuccess,
  toastCustomized,
} from '../../../common/toast-default-config'
import AlertDialogSimple from '../../../common/alert-dialog-simple'
import ModalSimple from '../../../common/modal-simple'
import { isSuccess } from '../../../../helpers/utils'
import Pagination from '../../../common/pagination'
import WebForm from './WebForm'
import CreateWebData from './CreateWebData'
import { LanguageContext } from '../../../../context/LanguageContext'

const handleApiError = (toast, errorMessage = 'forms:actionUncompleted') => {
  toast(toastCustomizedError(errorMessage, 'forms:support'))
}

const WebData = () => {
  const { t } = useTranslation(['common', 'forms', 'web-information'])

    const { language } = useContext(LanguageContext)
  const authToken = useSelector(getAuthToken)
  const toast = useToast()
  const appId = useSelector(getUserApplicationId)
  const [idSection, setIdSection] = useState(0)
  const [sectionName, setSectionName] = useState('')
  const [activeValue, setActiveValue] = useState(false)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [companyDetails, setCompanyDetails] = useState([])

  const modalDisclosure = useDisclosure()
  const modalDisclosure2 = useDisclosure()
  const getCompanyDetail = async () => {
    setIsLoading(true)
    try {
      const res = await getAllCompanyDetails({ empresaId: appId, language ,page })
      setTotalPages(res.data.totalPages)
      if (res.data.totalPages < page) setPage(1)
      setCompanyDetails(res.data.data)
    } catch (error) {
      console.error('Error fetching company details', error)
      setCompanyDetails([])
    } finally {
      setIsLoading(false)
    }
  }

  const createCompany = async formData => {
    try {
      const response = await createCompanyDetail(formData, authToken)
      if (response?.data?.updated) {
        toast(
          toastCustomized(
            t('forms:existingType'),
            `${t('forms:updatedType')}. ID: ${response?.data?.data?.id}`,
            'success',
            6000,
          ),
        )
      } else if (isSuccess(response.status)) {
        toast(toastCustomizedSuccess(t('forms:actionCompleted')))
      } else {
        handleApiError(toast)
      }
      getCompanyDetail()
    } catch (error) {
      handleApiError(toast)
    }
  }

  const onSubmit = datos => {
    const formData = {
      empresaId: appId,
      tipoEnlace: { id: parseInt(datos.type) },
      valor: datos.value,
      esHeader: datos.headerChecked,
      esFooter: datos.footerChecked,
      categoria: datos.categoria,
      url: datos.url,
    }
    createCompany(formData)
  }

  const handleDelete = async () => {
    try {
      const res = await deleteCompanyDetails(idSection, authToken)
      const status = isSuccess(res.status)
      toast(status ? toastSuccess : toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
      modalDisclosure2.onClose()
      getCompanyDetail()
    } catch (error) {
      console.error('Error deleting company detail', error)
      handleApiError(toast)
    }
  }

  const handleActiveValue = useCallback(
    value => {
      setActiveValue(value)
    },
    [setActiveValue],
  )

  const onEditClick = idSectionEdit => {
    setIdSection(idSectionEdit)
    modalDisclosure.onOpen()
  }

  const columns = useMemo(
    () => [
      { Header: 'Id', accessor: 'id' },
      {
        Header: t('forms:type'),
        accessor: 'tipoEnlace',
        Cell: ({ cell }) => (
          <Stack spacing='5px' isInline>
            <Text>
              {cell.row.values.tipoEnlace.descripcion.length > 26
                ? `${cell.row.values.tipoEnlace.descripcion.substring(0, 26)}...`
                : cell.row.values.tipoEnlace.descripcion}
            </Text>
          </Stack>
        ),
      },
      {
        Header: t('forms:value'),
        accessor: 'valor',
        Cell: ({ cell }) => (
          <Stack spacing='5px' isInline>
            <Text>
              {cell.row.values.valor.length > 26
                ? `${cell.row.values.valor.substring(0, 26)}...`
                : cell.row.values.valor}
            </Text>
          </Stack>
        ),
      },
      {
        Header: t('web-information:active'),
        accessor: 'esFooter',
        Cell: ({ cell }) => <Text>{cell.row.values.esFooter ? 'SI' : 'NO'}</Text>,
      },
      {
        Header: t('common:actions'),
        accessor: null,
        Cell: ({ cell }) => (
          <Stack spacing='5px' isInline>
            <ButtonEdit mb={4} onClick={() => onEditClick(cell.row.values.id)} />
            <ButtonDelete
              mb={4}
              onClick={() => {
                setIdSection(cell.row.values.id)
                setSectionName(cell.row.values.tipoEnlace.descripcion)
                modalDisclosure2.onOpen()
              }}
            />
          </Stack>
        ),
      },
    ],
    [t],
  )

  useEffect(() => {
    getCompanyDetail()
    getLinkTypes({ empresaId: appId })
  }, [page])
  return (
    <Box>
      <AlertDialogSimple
        isOpen={modalDisclosure2.isOpen}
        onClose={modalDisclosure2.onClose}
        onAccept={handleDelete}
        title={`${t('common:delete')}: ${sectionName}`}
        size='lg'
      >
        <Text>{t('common:deletingItem')}</Text>
      </AlertDialogSimple>

      <ModalSimple
        isOpen={modalDisclosure.isOpen}
        onClose={modalDisclosure.onClose}
        title={`${t('common:edit')}: ${sectionName}`}
        size='lg'
      >
        <CreateWebData onFinish={getCompanyDetail} idSection={idSection} onClose={modalDisclosure.onClose} />
      </ModalSimple>

      <Panel rounded='md' mb='1.5rem'>
        <WebForm
          onSubmit={onSubmit}
          companyDetails={companyDetails}
          activeValue={activeValue}
          handleActiveValue={handleActiveValue}
        />
      </Panel>

      <Box mt='8'>
        {isLoading ? (
          <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
        ) : (
          <>
            {companyDetails?.length > 0 ? (
              <Table columns={columns} data={companyDetails} />
            ) : (
              <Text textAlign='center' color='gray.500' px='5' py='10'>
                {companyDetails ? t('common:zeroItems') : t('common:errorGettingItems')}
              </Text>
            )}
            {totalPages > 0 && <Pagination currentPage={page} setPage={setPage} totalPages={totalPages} />}
          </>
        )}
      </Box>
    </Box>
  )
}

export default WebData
