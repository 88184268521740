import React, { useState, useMemo, useEffect } from 'react'
import { Box, Button, Grid, PseudoBox, FormLabel, Checkbox } from '@chakra-ui/core'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getAuthToken, getUserRolId } from '../../../../redux/selectors'
import { usePermissionChecker } from '../../../hooks'
import FormInput from '../../../forms/form-input'
import moment from 'moment'
import 'react-dates/initialize'
import { DateRangePicker } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'
import { CATEGORIES_DEFAULT } from '../../../../helpers/permissions'
import { getDinamicOffers } from '../../../../api/intranet/marca-blanca/banner'

export const SimpleCategoryForm = ({ onSubmit, formData = false, buttonLabel = false, ...restProps }) => {
  const authToken = useSelector(getAuthToken)
  const { register, handleSubmit, formState, control, setValue, reset } = useForm({ defaultValues: formData })
  const { t } = useTranslation(['common', 'forms', 'banners', 'sections'])
  const { canDoThis } = usePermissionChecker()
  const userRolId = useSelector(getUserRolId)
  const permisosUserCountry = userRolId == 2 ? true : false

  const [categoryState, setCategoryState] = useState({
    isActive: formData ? formData.activo : false,
    isDefault: formData ? formData.default : false,
  })

  //Traer oferta dinámica
  const [request, setRequest] = useState({
    isLoading: false,
    items: [],
    error: false,
  })

  async function getDinamicOffer() {
    setRequest(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const res = await getDinamicOffers(authToken)
      setRequest(state => ({
        ...state,
        isLoading: false,
        items: (res.status && res.data) || [],
      }))
    } catch (error) {
      setRequest(state => ({
        ...state,
        isLoading: false,
        error: true,
      }))
    }
  }

  // useEffect(() => {
  //   getDinamicOffer()
  // }, [])

  //Check Formulario: oferta dinámica
  const [check, setCheck] = useState(formData ? formData.ofertaDinamicaId : false)
  const handleCheck = () => {
    setCheck(!check)
  }

  const handleChange = (field) => {
    setCategoryState((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }))
  }

  /* Habilita o no el check de default */
  const permisoCrud = useMemo(
    () =>
      canDoThis(
        CATEGORIES_DEFAULT,
        () => ({
          disabled: false,
        }),
        () => ({
          disabled: true,
        }),
      ),
    [canDoThis],
  )

  // Hooks for the DateRangePicker
  const [{ startDate, endDate }, setRangeDate] = useState({
    startDate: formData ? moment(formData.fechaInicio) : moment(),
    endDate: formData ? moment(formData.fechaFin) : null,
  })

  const [focusedInput, setFocusedPicker] = useState(null)

  useEffect(() => {
    if (startDate === null) {
      setRangePicker(state => ({
        ...state,
        startDate: moment(),
      }))
    }
  }, [startDate, endDate])

  const resetForm = () => {
    setCategoryState({
      isActive: false,
      isDefault: false
    })
    setRangeDate({
      startDate: moment(),
      endDate: null,
    });
  };

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      resetForm()
      reset({})
    }
  }, [formState, reset])

  function setRangePicker({ startDate, endDate }) {
    setRangeDate({ startDate, endDate })
  }

  const printOption = option => (
    <option key={option.id} value={option.id}>
      {option.title}
    </option>
  )

  return (
    <PseudoBox
      as='form'
      onSubmit={handleSubmit(d => {
        let titulo = ''
        if (d.dinamicOffer && check) {
          const ofertaDinamica = request.items.find(item => item.id === parseInt(d.dinamicOffer))
          titulo = ofertaDinamica.title
        } else {
          titulo = ''
          d.dinamicOffer = false
        }

        return onSubmit({
          ...d,
          titulo,
          endDate,
          startDate,
          isActive: categoryState.isActive,
          isDefault: categoryState.isDefault,
        })
      })}
      {...restProps}
    >
      <Grid templateColumns={formData === false ? { base: 'repeat(1, 1fr)', md: 'repeat(4, 1fr)' } : ''} gap={6}>
        {/* Nombre */}
        <Box alignItems='center'>
          <FormLabel isDisabled={check} mr='1' isRequired>
            {t('forms:name')}
          </FormLabel>
          <FormInput
            isRequired
            name='nombre'
            inputProps={{
              isDisabled: check,
              type: 'text',
              placeholder: `${t('forms:name')}`,
              ref: register,
            }}
          />
        </Box>
        {/* Descripcion */}
        <Box alignItems='center'>
          <FormLabel isDisabled={check} mr='1'>
            {t('forms:description')}
          </FormLabel>
          <FormInput
            name='descripcion'
            inputProps={{
              isDisabled: check,
              type: 'text',
              placeholder: `${t('forms:description')}`,
              ref: register,
            }}
          />
        </Box>
        {/* Orden */}
        <Box alignItems='center'>
          <FormLabel mr='1'>{t('forms:order')}</FormLabel>
          <FormInput
            name='orden'
            inputProps={{
              type: 'number',
              placeholder: `${t('forms:order')}`,
              ref: register,
            }}
          />
        </Box>
        {/* Vigencia (Data Picker) */}
        <Box alignItems='center'>
          <FormLabel>{t('forms:validity')}</FormLabel>
          <Box>
            <DateRangePicker
              startDate={startDate}
              startDateId='fechaInicio'
              endDate={endDate}
              focusedInput={focusedInput}
              onDatesChange={({ startDate, endDate }) => setRangePicker({ startDate: startDate, endDate: endDate })}
              onFocusChange={focusedInput => setFocusedPicker(focusedInput)}
              small
              orientation='horizontal'
              startDatePlaceholderText={t('common:startDate')}
              endDatePlaceholderText={t('common:endDate')}
            />
          </Box>
        </Box>
        <Box d='flex' alignItems='center'>
        <Box alignSelf='center' style={{ marginLeft: '5px' }}>
          {t('sections:active')}
          <Checkbox size='lg' isChecked={categoryState.isActive} ml='5' onChange={() => handleChange('isActive')} />
        </Box>
        {permisosUserCountry  && <Box alignSelf='center' style={{ marginLeft: '20px' }}>
          {t('sections:isDefault')}
          <Checkbox size='lg' isChecked={categoryState.isDefault} ml='5' onChange={() => handleChange('isDefault')} mt='2px'/>
        </Box>}
        </Box>
        {/* <Box>
          <Checkbox size='lg' mr='2' isChecked={check} onChange={handleCheck} />
          <FormLabel isRequired>{t('forms:dinamic_offer')}</FormLabel>
          <Controller
            isRequired
            control={control}
            name='dinamicOffer'
            defaultValue={formData.ofertaDinamicaId ? formData.ofertaDinamicaId : false}
            render={({ onChange, value, name }) => (
              <SelectAsync
                onChange={e => onChange(e.target.value)}
                placeholder={`${t('forms:selectOne')} ${t('forms:dinamic_offer')}`}
                name={name}
                value={formData.ofertaDinamicaId ? formData.ofertaDinamicaId : false}
                isLoading={request.isLoading}
                isDisabled={!check}
              >
                {!request.error && request.items && request.items.map(printOption)}
              </SelectAsync>
            )}
          />
        </Box> */}
        {/* Desactivar Button */}
      </Grid>
      <Box d='flex' alignItems='flex-end' justifyContent='left' mt='5'>
        <Button variantColor='blue' variant='outline' isLoading={formState.isSubmitting} type='submit'>
          {t('common:save')}
        </Button>
      </Box>
    </PseudoBox>
  )
}
