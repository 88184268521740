import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import { DEFAULT_LANGUAGE } from './constants'


const isDev = process.env.REACT_APP_ENV === 'development'


const backendOpts = {
  loadPath: '/locales/{{lng}}/{{ns}}.json',
}


i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    backend: backendOpts,
    fallbackLng: isDev ? ['dev'] : [DEFAULT_LANGUAGE],
    detection: {
      order: ['navigator', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'htmlTag', 'path', 'subdomain'], 
      caches: ['cookie', 'localStorage'], 
      lookupCookie: 'i18next', 
      lookupLocalStorage: 'i18nextLng', 
      lookupQuerystring: 'lng',
      checkWhitelist: true, 
    },
    ns: [
      'applications',
      'auto-login',
      'banners',
      'bookings',
      'common',
      'comunications',
      'consultations',
      'derive',
      'exchange',
      'forms',
      'hotels',
      'incentives',
      'login',
      'packages',
      'sections',
      'suppliers',
      'template',
      'translation',
      'web-information',
    ],
    defaultNS: 'common',
    supportedLngs: ['dev', 'en', 'es', 'pt-BR'],
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
