import { Box, Button, Grid, Select, useToast } from '@chakra-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSectionData } from '../../../hooks/useSectionData'
import FormInput from '../../forms/form-input'
import { getAllCountries } from '../../../api/application'
import { toastCustomizedError } from '../../common/toast-default-config'
import SelectAsync from '../../common/select-async'
import { FILTER_KEYS } from '.'
import { DateRangePicker } from 'react-dates'
import { getAllSupplierByGroup } from '../../../api/groups-api'
import { destinationOptions } from './group-enum'
import { getAuthToken, getUserRolId } from '../../../redux/selectors'
import { useSelector } from 'react-redux'

export default function GroupSearchForm({ onFilter, updateSearch, ...restProps }) {
  const { filters, handleFilters, handleResetFilters } = useSectionData()
  const { t } = useTranslation(['common', 'forms', 'consultations', 'comunications'])
  const toast = useToast()
  const authToken = useSelector(getAuthToken)
  const userRolId = useSelector(getUserRolId)
  const permisosUserAdmin = userRolId === 1 ? true : false
  const [focusedInput, setFocusedInput] = useState(null)
  const [countries, setCountries] = useState(null)
  const [countryAdmin, setCountryAdmin] = useState(0)
  const [supplier, setSupplier] = useState({
    data: [],
    isLoading: true,
  })

  const [{ startDate, endDate }, setRangePicker] = useState({
    startDate: undefined,
    endDate: undefined,
  })

  function setFocusedPicker(focusedInput) {
    setFocusedInput(() => focusedInput)
  }

  function resetFilters() {
    handleResetFilters()
    setRangePicker({
      startDate: undefined,
      endDate: undefined,
    })
    setCountryAdmin(0)
  }

  const getSupplier = async () => {
    setSupplier(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const response = await getAllSupplierByGroup()
      setSupplier(() => ({
        data: response.data,
        isLoading: false,
      }))
    } catch (error) {
      setSupplier(state => ({
        ...state,
        error: error,
      }))
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  const getCountries = async () => {
    try {
      const response = await getAllCountries(authToken)
      setCountries(() => response.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getSupplier()
    getCountries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSearch])

  return (
    <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: `repeat(${permisosUserAdmin ? 6 : 5}, 1fr)` }} gap={6} {...restProps}>
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        startDateId='fromDate'
        endDateId='untilDate'
        focusedInput={focusedInput}
        onDatesChange={({ startDate, endDate }) => {
          setRangePicker({ startDate: startDate, endDate: endDate })
          handleFilters(FILTER_KEYS.startDate, startDate?.hours(0).minutes(0).toISOString(true))
          handleFilters(FILTER_KEYS.endDate, endDate?.hours(23).minutes(59).toISOString(true))
        }}
        onFocusChange={focusedInput => setFocusedPicker(focusedInput)}
        small
        orientation='horizontal'
        displayFormat='DD/MM/YYYY'
        startDatePlaceholderText={t('common:startDate')}
        endDatePlaceholderText={t('common:endDate')}
        isOutsideRange={() => false}
      />
      <SelectAsync
        onChange={({ target }) => {
          handleFilters(FILTER_KEYS.supplier, target.value)
        }}
        placeholder={`${t('forms:suppliers')} - ${t('forms:all')}`}
        value={filters.supplier}
      >
        {supplier.data
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(({ id, name }) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
      </SelectAsync>
      <SelectAsync
        onChange={({ target }) => {
          handleFilters(FILTER_KEYS.destination, target.value)
        }}
        placeholder={`${t('forms:destination')} - ${t('forms:all')}`}
        value={filters.destination}
      >
        {destinationOptions.map(({ value, name }) => (
          <option key={value} value={value}>
            {name}
          </option>
        ))}
      </SelectAsync>
      <FormInput
        onChange={e => handleFilters(FILTER_KEYS.days, e.target.value)}
        value={filters.days}
        name='days'
        inputProps={{
          placeholder: `Dias`,
        }}
      />
      {permisosUserAdmin && countries && (
        <Box>
          <Select
            onChange={e => {
              setCountryAdmin(e.target.value)
              handleFilters(FILTER_KEYS.countryId, e.target.value)
            }}
            placeholder={t('forms:countries')}
            name='country'
            value={countryAdmin}
          >
            {countries
              .sort((a, b) => a.nombre.localeCompare(b.nombre))
              .map(({ id, nombre }) => (
                <option key={id} value={id}>
                  {nombre}
                </option>
              ))}
          </Select>
        </Box>
      )}
      <Box style={{ display: 'flex', gap: '1rem' }}>
        <Button maxW={{ base: '100%', md: '120px' }} variantColor='blue' variant='outline' onClick={onFilter}>
          {t('common:filter')}
        </Button>

        <Button variantColor='blue' variant='outline' onClick={resetFilters}>
          {t('comunications:cleanFilter')}
        </Button>
      </Box>
    </Grid>
  )
}
