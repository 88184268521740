import React, { Suspense } from "react";
import { Provider as ReduxProvider  } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";
import { ThemeProvider, CSSReset } from "@chakra-ui/core";
import customTheme from "./theme";
import Root from "./components/root";
import 'aos/dist/aos.css';
import { LanguageProvider } from "./context/LanguageContext";

const config = (theme, colorMode) => ({
	...colorMode,
	light: {
		...colorMode.light,
		bg: "#ffffff",
	}
});

function App() {
	return (
		<Suspense fallback="">
			<ReduxProvider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<LanguageProvider>
						<ThemeProvider theme={customTheme}>
							<CSSReset config={config}/>
							<Root />
						</ThemeProvider>
					</LanguageProvider>
				</PersistGate>
			</ReduxProvider>
		</Suspense>
	);
}

export default App;
