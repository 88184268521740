import { Box, Button, Grid, PseudoBox, Checkbox, useToast, FormLabel, Textarea, Flex } from '@chakra-ui/core'
import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { getCompanyTypes } from '../../../../api/web-information'
import { getCategoriesOfSuppliers } from '../../../../api/intranet'
import { useSelector } from 'react-redux'
import { getAuthToken, getUserCountryId } from '../../../../redux/selectors'
import SelectAsync from '../../../common/select-async'
import FormInput from '../../../forms/form-input'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import ImageInput from '../../../common/image-input'
import { toastCustomizedError } from '../../../common/toast-default-config'
import { VALUE_TYPE_KEYS } from '../../../../helpers/commons'
import './styles.css'
import { ColorPicker } from 'antd'
import CustomCheckbox from '../../../common/CustomCheckbox'
import { LanguageContext } from '../../../../context/LanguageContext'
import { set } from 'draft-js/lib/DefaultDraftBlockRenderMap'

const { COLOR, IMAGE, VALUE, LINK, PAQUETE } = VALUE_TYPE_KEYS
const inputTextEntries = [VALUE, LINK]

const defaultType = {
  componentToRender: null,
  typeValue: null,
}

const WebForm = ({ onSubmit, companyDetails, activeValue, handleActiveValue, ...restProps }) => {
  const { register, handleSubmit, formState, reset, setValue } = useForm()
  const { t } = useTranslation(['sections', 'web-information', 'forms', 'common'])
  const [categoryId, setCategoryId] = useState(undefined)
  const [type, setType] = useState(defaultType)
  const [valueValue, setValueValue] = useState()
  const [urlLogo, setUrlLogo] = useState()
  const [categoryValue, setCategoryValue] = useState()
  const [color, setColor] = useState('#8dd3bb')
  const [textColor, setTextColor] = useState('#FFFFFF')
  const [characterCount, setCharacterCount] = useState(0)
  const maxCharacterCount = 500
  const { language } = useContext(LanguageContext)
  const [textGoogle, setTextGoogle] = useState('')

  const authToken = useSelector(getAuthToken)
  const userCountryId = useSelector(getUserCountryId)
  const toast = useToast()

  const [categories, setCategories] = useState({
    data: [],
    isLoading: true,
  })

  const [types, setTypes] = useState({
    data: [],
    isLoading: true,
  })

  const getCompanyTypesCombo = async () => {
    setTypes(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      await getCompanyTypes(userCountryId, language,authToken).then(res => {
        const sortedData = res.data.sort((a, b) => {
          const descripcionA = a.descripcion.toLowerCase()
          const descripcionB = b.descripcion.toLowerCase()
          if (descripcionA < descripcionB) {
            return -1
          }
          if (descripcionA > descripcionB) {
            return 1
          }
          return 0
        })
        setTypes({
          data: sortedData,
          isLoading: false,
        })
      })
    } catch (error) {
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:supporttttt')))
    }
  }

  const getSuppliersCategories = async () => {
    setCategories(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const response = await getCategoriesOfSuppliers(userCountryId)
      setCategories({
        data: response.data,
        isLoading: false,
      })
    } catch (error) {
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  useEffect(() => {
    getSuppliersCategories()
    getCompanyTypesCombo()
  }, [])

  const onSubmitForm = useCallback(
    data => {
      const datos = {
        ...data,
        headerChecked: activeValue,
        footerChecked: activeValue,
        color,
        categoria: { id: categoryId },
      }
      if (type.componentToRender === PAQUETE) {
        datos.value = 'PAQUETE'
      }
      if (type.componentToRender === COLOR) {
        datos.value = JSON.stringify({ background: color, text: textColor })
      }
      onSubmit(datos)
      resetForm()
    },
    [activeValue, color, categoryId, activeValue],
  )

  const createWebData = useCallback(() => {
    const datos = {
      headerChecked: activeValue,
      footerChecked: activeValue,
      categoria: { id: categoryId },
    }
    document.getElementById('web-data-form').reset()
    onSubmit(datos)
  }, [activeValue, categoryId])

  const resetForm = () => {
    document.getElementById('web-data-form').reset()
    setValueValue(undefined)
    setUrlLogo(undefined)
    setCategoryValue(undefined)
    setType(defaultType)
    setCharacterCount(0)
    setColor('#8dd3bb')
    setTextColor('#FFFFFF')
    handleActiveValue(false)
  }

  const tipoEnlaceIdsOriginal = companyDetails.map(obj => obj.tipoEnlace.id)
  const arrayFiltrado = types.data.filter(obj => !tipoEnlaceIdsOriginal.includes(obj.id))

  const isSectionShow =
    type.componentToRender === COLOR ||
    type.componentToRender === IMAGE ||
    inputTextEntries.includes(type.componentToRender)

    useEffect(() => {
      if( type?.nameType === 'Google-Tag-Body'){
        setTextGoogle('<noscript><iframe... || <script><iframe...')
      }else if (type?.nameType === 'Google-Tag-Head'){
        setTextGoogle('<script>(function...')
      }else if (type?.nameType === 'Google-Tag-Manager'){
        setTextGoogle(' <script async src="...')
      }else {
        setTextGoogle('')
      }
    }, [type])

  return (
    <PseudoBox
      id='web-data-form'
      as='form'
      onSubmit={handleSubmit(d => {
        const category = categories.data.find(cat => cat.nombre === categoryValue)
        const newData = {
          ...d,
          category,
        }
        if (type.componentToRender !== IMAGE) {
          newData.value = valueValue
        }
        return onSubmitForm(newData)
      })}
      {...restProps}
      style={{ backgroundColor: 'white', borderRadius: 10 }}
    >
      <Grid
        templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
        gap={4}
        style={{ marginBottom: !!type.typeValue ? '1.5rem' : '0' }}
      >
        {/* Tipo */}
        <Box>
          <FormLabel htmlFor='type' isRequired>
            {t('forms:type')}
          </FormLabel>
          <SelectAsync
            isRequired
            name='type'
            value={type.typeValue || ''}
            onChange={e => {
              const typeDetail = types?.data?.find(type => type.id === parseInt(e.target.value))
              setCategoryId(Number(typeDetail?.categoria?.id))
              setType({
                componentToRender: typeDetail?.categoria?.tipoValor,
                name: typeDetail?.categoria?.nombre,
                typeValue: e.target.value,
                nameType: typeDetail?.nombre,
                descriptionType: typeDetail?.descripcion,
              })
            }}
            nativeProps={{ ref: register, placeholder: t('forms:selectOption') }}
            isLoading={types?.isLoading}
          >
            {arrayFiltrado?.map(type => (
              <option key={type.id} value={type.id}>
                {type.descripcion}
              </option>
            ))}
          </SelectAsync>
        </Box>
  
      </Grid>
      {type.componentToRender && type.componentToRender !== 'PAQUETE' && (
        <FormLabel htmlFor='value' isRequired mb={'15px'}>
          {t('forms:' + type.componentToRender.toLowerCase())}
        </FormLabel>
      )}
      {isSectionShow && (
        <Grid
          templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
          gap={4}
          style={{ marginBottom: '2.5rem' }}
        >
          {type.componentToRender === COLOR && (
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
                marginBottom: '10px',
              }}
            >
              <Box>
                <FormLabel>{t('common:background')}</FormLabel>
                <ColorPicker
                  value={color}
                  onChange={color => {
                    const backgroundColorHex = color.toHex()
                    setColor(`#${backgroundColorHex}`)
                  }}
                />
              </Box>
              <Box>
                <FormLabel>{t('common:letter')}</FormLabel>
                <ColorPicker
                  value={textColor}
                  onChange={color => {
                    const textColorHex = color.toHex()
                    setTextColor(`#${textColorHex}`)
                  }}
                />
              </Box>
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                backgroundColor={color}
                color={textColor}
                style={{ height: '100%', padding: '10px', marginLeft: '20px' }}
              >
                <p>
                  {t('common:exampleMarcaBlanca1')}
                  <br></br>
                  {t('common:exampleMarcaBlanca2')}
                </p>
              </Box>
            </Box>
          )}
          {type.componentToRender === IMAGE && (
            <Grid gap={4}>
              <Box>
                {/* imagen URL */}
                <ImageInput
                  isRequired
                  name='value'
                  setValue={setValue}
                  inputProps={{
                    placeholder: `URL ${t('forms:image')}`,
                    ref: register,
                    type: 'text',
                  }}
                />
              </Box>
              {type.name !== 'Header-Imagen' && (
                <Box>
                  <FormLabel htmlFor='type'>{t('forms:url')}</FormLabel>
                  <FormInput
                    name='url'
                    value={urlLogo}
                    onChange={e => setUrlLogo(e.target.value)}
                    inputProps={{
                      type: 'text',
                      ref: register,
                    }}
                  />
                </Box>
              )}
            </Grid>
          )}
          {inputTextEntries.includes(type.componentToRender) && (
            <Box>
              {type.descriptionType !== 'Acerca de Nosotros' && type.nameType !== 'about-us-footer' ? (
                <FormInput
                  isRequired
                  name='value'
                  value={valueValue}
                  onChange={e => setValueValue(e.target.value)}
                  placeholder={isSectionShow && textGoogle && (
                    textGoogle
                  )}
                  inputProps={{
                    type: 'text',
                    ref: register,
                  }}
                />
              ) : (
                <>
                  <Textarea
                    isRequired
                    name='value'
                    value={valueValue}
                    height={180}
                    onPaste={e => {
                      e.preventDefault()
                      const pastedData = e.clipboardData.getData('text')
                      const trimmedData = pastedData.slice(0, 500)
                      setValueValue(trimmedData)
                      setCharacterCount(trimmedData.length)
                    }}
                    onChange={e => {
                      const newValue = e.target.value
                      if (newValue.length <= 500) {
                        setValueValue(newValue)
                        setCharacterCount(newValue.length)
                      }
                    }}
                    inputProps={{
                      type: 'text',
                      ref: register,
                    }}
                  />
                  <div className={characterCount === maxCharacterCount ? 'red-text' : ''} style={{ fontSize: '12px' }}>
                    Characters: {characterCount}/{maxCharacterCount}
                  </div>
                </>
              )}
            </Box>
          )}
        </Grid>
      )}
      {/* Checkbox */}
      {!!type.typeValue && (
        <Grid
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-start',
          }}
        >
          <CustomCheckbox
            label={t('web-information:active')}
            active={activeValue}
            setActiveValue={handleActiveValue}
            justifyContent='flex-start'
            alignItems='center'
          />
        </Grid>
      )}
      <Button mt={6} variantColor='blue' variant='outline' isLoading={formState.isSubmitting} type='submit'>
        {t('common:save')}
      </Button>
    </PseudoBox>
  )
}

export default WebForm
