import { Button, Grid, PseudoBox, FormLabel, useToast, Box } from '@chakra-ui/core'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import FormInput from '../../../forms/form-input'
import { useSelector } from 'react-redux'
import { getAuthToken } from '../../../../redux/selectors'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { toastCustomizedError } from '../../../common/toast-default-config'
import { getCategoryDetails } from '../../../../api/web-information'
import SelectAsync from '../../../common/select-async'
import { useFormControls } from '../../../hooks'

export default function TypeForm({ onSubmit, ...restProps }) {
  const authToken = useSelector(getAuthToken)
  const { register, handleSubmit } = useForm()
  const { t } = useTranslation(['forms'])
  const toast = useToast()
  const { isSubmitBlocked } = useFormControls()

  ////Prop function OnSubmit

  function onSubmitt(datos) {
    document.getElementById('type-form').reset()
    onSubmit(datos)
  }

  const [categories, setCategories] = useState({
    data: [],
    isLoading: true,
  })

  const getCategories = async () => {
    setCategories(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const response = await getCategoryDetails(authToken)
      setCategories(() => ({
        data: response.data,
        isLoading: false,
      }))
    } catch (error) {
      setCategories(state => ({
        ...state,
        error: true,
      }))
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }
  useEffect(() => {
    getCategories()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PseudoBox
      id='type-form'
      as='form'
      onSubmit={handleSubmit(d => onSubmitt(d))}
      {...restProps}
      style={{ backgroundColor: 'white', borderRadius: 10 }}
    >
      <Grid
        templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
        gap={4}
        style={{ marginBottom: '1.5rem' }}
      >
        {/* Nombre */}
        <Box>
          <FormLabel htmlFor='nombre' isRequired>
            {t('forms:name')}
          </FormLabel>
          <FormInput
            isRequired
            maxLength='255'
            name='nombre'
            inputProps={{
              ref: register,
            }}
          />
        </Box>
        {/* Descripcion */}
        <Box>
          <FormLabel htmlFor='descripcion' isRequired>
            {t('forms:description') + ' ES'}
          </FormLabel>
          <FormInput
            isRequired
            maxLength='255'
            name='descripcion'
            inputProps={{
              ref: register,
            }}
          />
        </Box>
        {/* Descripcion portugues*/}
        <Box>
          <FormLabel htmlFor='descripcionPt' isRequired>
            {t('forms:description') + ' PT'}
          </FormLabel>
          <FormInput
            isRequired
            maxLength='255'
            name='descripcionPt'
            inputProps={{
              ref: register,
            }}
          />
        </Box>
        {/* Categoria */}
        <Box>
          <FormLabel htmlFor='categoria_id' isRequired>
            {t('forms:category')}
          </FormLabel>
          <SelectAsync
            name='categoria.id'
            isLoading={categories.isLoading}
            placeholder={t('forms:selectOption')}
            nativeProps={{ ref: register }}
            isRequired
          >
            {categories.data.map(({ id, descripcion }) => (
              <option key={id} value={id}>
                {descripcion}
              </option>
            ))}
          </SelectAsync>
        </Box>
      </Grid>
      <Button variantColor='blue' variant='outline' type='submit' isLoading={isSubmitBlocked}>
        {t('forms:save')}
      </Button>
    </PseudoBox>
  )
}
