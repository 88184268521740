import React, { createContext, useEffect, useState } from 'react';
import i18n from '../i18n';
import {
  getUserCountryId,
} from '../redux/selectors'
import { useSelector } from 'react-redux';
import { DEFAULT_LANGUAGE } from '../constants';

const isDev = process.env.REACT_APP_ENV === 'development'
const countryLanguageMap = {
  1: "es",
  2: "es",
  3: "pt-BR",
  4: "pt-BR",
};

export const LanguageContext = createContext();
export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(isDev ? 'dev' : DEFAULT_LANGUAGE);
  const userCountryId = useSelector(getUserCountryId)

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
  };

  useEffect(() => {
    if (userCountryId) {
      changeLanguage(countryLanguageMap[userCountryId])
    }
  }, [userCountryId]);

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
