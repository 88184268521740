import { Box, Button, FormLabel, PseudoBox, Stack } from '@chakra-ui/core'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  iconInstance,
  sectionInstance,
  getCustomSectionById,
  getAllIconTypesEndpoint,
  getSectionTypesEndpoint,
} from '../../../api/section'
import useFetch from '../../../api/useFetch'
import { getAuthToken, getUserApplicationId } from '../../../redux/selectors'
import SelectAsync from '../../common/select-async'
import { useConfigWithAuth, useFormControls } from '../../hooks'
import FormInput from '../form-input'
import FormInputTextArea from '../form-textarea'

import { DateRangePicker } from 'react-dates'
import moment from 'moment'
import AlertDialogSimple from '../../../components/common/alert-dialog-simple'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.bubble.css'

export default function OwnSectionForm({ onSubmit, formData = false, buttonLabel = false, ...restProps }) {
  const { t } = useTranslation(['sections'])
  const appId = useSelector(getUserApplicationId)
  const authToken = useSelector(getAuthToken)
  const { configAuth } = useConfigWithAuth()
  const { isSubmitBlocked } = useFormControls()
  const { register, handleSubmit } = useForm({
    defaultValues: formData,
  })
  const [{ startDate, endDate }, setRangePicker] = useState({
    startDate: moment(),
    endDate: null,
  })
  const [focusedInput, setFocusedPicker] = useState(null)
  const [selectedSectionType, setSelectedSectionType] = useState(undefined)
  const [contentValue, setContentValue] = useState(undefined)
  const [categoriaUbicacion, setCategoriaUbicacion] = useState(false)
  const [{ sectionTypeValue, sectionNameValue, iconValue }, setValues] = useState({
    sectionTypeValue: undefined,
    sectionNameValue: '',
    iconValue: 0,
  })
  const sectionTypesRequest = useFetch({
    api: sectionInstance,
    method: 'get',
    url: getSectionTypesEndpoint(),
    config: configAuth,
  })

  const iconTypesRequest = useFetch({
    api: iconInstance,
    method: 'get',
    url: getAllIconTypesEndpoint(),
    config: configAuth,
  })

  useEffect(() => {
    if (startDate === null) {
      setRangePicker(state => ({
        ...state,
        startDate: moment(),
      }))
    }
  }, [startDate, endDate])

  var toolbar = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],

    [{ header: 1 }, { header: 2 }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ direction: 'rtl' }],

    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    ['link', 'image', 'video'],

    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],

    ['clean'],
  ]

  function loadValuesInputs(miSection) {
    setContentValue(miSection.contenido)
    setRangePicker({
      startDate: moment(miSection.fechaInicio),
      endDate: miSection.fechaFin ? moment(miSection.fechaFin) : null,
    })
    setValues(state => ({
      ...state,
      sectionTypeValue: miSection.tipoSeccion?.nombre,
      sectionNameValue: miSection.descripcion,
      iconValue: miSection.icon?.id,
    }))
    setCategoriaUbicacion(miSection.categoriaUbicacion ? true : false)
    setSelectedSectionType(miSection.tipoSeccion?.nombre)
  }

  async function getSection(sectionId) {
    let miSection = {}

    await getCustomSectionById(sectionId, authToken)
      .then(res => {
        miSection = res.data
      })
      .catch(error => console.log('ErrorSectionById', error))
    loadValuesInputs(miSection)
  }

  useEffect(() => {
    getSection(restProps.idSectionn)
  }, [])

  function onSubmitt(datos) {
    const tipoSeccionSeleccionada = sectionTypesRequest.response.find(section => section.nombre === sectionTypeValue)
    let formData = {
      empresaId: appId,
      descripcion: String(datos.sectionName),
      fechaInicio: startDate._d,
      fechaFin: endDate?._d === 'Invalid Date' ? null : endDate?._d,
      icon: { id: Number(datos.icon) },
      tipoSeccion: { id: tipoSeccionSeleccionada.id },
      contenido: contentValue,
    }

    onSubmit(formData)
  }

  function onChangeTS(e) {
    setValues(state => ({
      ...state,
      sectionTypeValue: e,
    }))
    setSelectedSectionType(e)
    setContentValue('')
  }

  function onChangeN(e) {
    setValues(state => ({
      ...state,
      sectionNameValue: e,
    }))
  }
  function onChangeI(e) {
    setValues(state => ({
      ...state,
      iconValue: e,
    }))
  }
  function onChangeURL(e) {
    setContentValue(e)
  }

  function onChangeText(e) {
    setContentValue(e)
  }

  const [controlAlert, setControlAlert] = useState({
    show: false,
  })

  const onCloseControlAlert = () => setControlAlert(state => ({ ...state, show: false }))

  const [control, setControl] = useState(false)

  async function onClose() {
    document.getElementById('customSection-form').reset()
    onCloseControlAlert()
  }

  return (
    <PseudoBox as='form' onSubmit={handleSubmit(d => onSubmitt(d))} {...restProps}>
      {/* Alert Control select */}
      <AlertDialogSimple
        control={control}
        isOpen={controlAlert.show}
        onClose={onCloseControlAlert}
        onAccept={onClose}
        acceptLabel={t('common:accept')}
      >
        {t('common:emptyContent')} <br />
      </AlertDialogSimple>
      <Stack>
        {/* Tipo seccion */}
        <Box>
          <FormLabel htmlFor='sectionType' isRequired>
            {t('forms:typeSection')}
          </FormLabel>
          <SelectAsync
            isRequired
            placeholder={t('sections:typeSection')}
            name='sectionType'
            value={selectedSectionType}
            nativeProps={{ ref: register }}
            isLoading={sectionTypesRequest.isLoading}
            disabled={categoriaUbicacion}
            onChange={e => {
              onChangeTS(e.target.value)
              setSelectedSectionType(e.target.value)
            }}
          >
            {sectionTypesRequest.response &&
              sectionTypesRequest.response.map(type => (
                <option key={type.id} value={type.nombre}>
                  {type.nombre}
                </option>
              ))}
          </SelectAsync>
        </Box>
        {/* Nombre */}
        <FormInput
          isRequired
          maxLength='255'
          label={t('forms:name')}
          name='sectionName'
          value={sectionNameValue}
          inputProps={{ placeholder: `${t('sections:name')}`, ref: register }}
          onChange={e => {
            onChangeN(e.target.value)
          }}
        />
        {/* data picker */}
        <Box>
          <FormLabel htmlFor='show-from-to'>{t('sections:show-from-to')}</FormLabel>
          <Box>
            <DateRangePicker
              startDateId='fechaInicio'
              endDateId='fechaFin'
              focusedInput={focusedInput}
              startDate={startDate}
              endDate={endDate}
              onDatesChange={({ startDate, endDate }) => setRangePicker({ startDate: startDate, endDate: endDate })}
              onFocusChange={focusedInput => setFocusedPicker(focusedInput)}
              small
              displayFormat='DD/MM/YYYY'
              startDatePlaceholderText={t('common:startDate')}
              endDatePlaceholderText={t('common:endDate')}
            />
          </Box>
        </Box>
        {/* Icono */}
        <Box>
          <FormLabel htmlFor='icon' isRequired>
            {t('sections:icon')}
          </FormLabel>
          <SelectAsync
            isRequired
            placeholder={t('sections:icon')}
            name='icon'
            value={iconValue}
            nativeProps={{ ref: register }}
            isLoading={iconTypesRequest.isLoading}
            onChange={e => {
              onChangeI(e.target.value)
            }}
          >
            {iconTypesRequest.response &&
              iconTypesRequest.response.map(type => (
                <option key={type.id} value={type.id}>
                  {type.descripcion}
                </option>
              ))}
          </SelectAsync>
        </Box>

        {selectedSectionType === 'URL' && (
          // URL
          <FormInput
            isRequired
            label={t('sections:url')}
            name='contenidoValue'
            value={contentValue}
            inputProps={{ placeholder: `${t('sections:urlPlaceholder')}`, ref: register }}
            onChange={e => {
              onChangeURL(e.target.value)
            }}
          />
        )}
        {selectedSectionType === 'HTML' && contentValue !== undefined && (
          // editor html
          <Box>
            <FormLabel>HTML</FormLabel>
            <Box
              style={{
                borderWidth: 0.5,
                borderColor: '#e5e5e5',
              }}
            >
              <ReactQuill
                style={{ display: 'flex', flexDirection: 'column', height: '460px', marginBottom: '30px' }}
                className=''
                modules={{ toolbar }}
                value={contentValue}
                onChange={setContentValue}
              />
            </Box>
          </Box>
        )}

        {selectedSectionType === 'SCRIPT' && (
          // SCRIPT component
          <Box>
            <FormLabel>Script</FormLabel>
            <Box
              style={{
                borderWidth: 0.5,
                borderColor: '#e5e5e5',
              }}
            >
              <FormInputTextArea
                isRequired
                label={'Script'}
                name='contenidoValue'
                value={contentValue}
                inputProps={{ placeholder: 'Script', ref: register }}
                onChange={e => {
                  onChangeText(e.target.value)
                }}
              />
            </Box>
          </Box>
        )}

        <Button mt={6} variantColor='blue' variant='outline' type='submit' isLoading={isSubmitBlocked}>
          {t('forms:save')}
        </Button>
      </Stack>
    </PseudoBox>
  )
}
