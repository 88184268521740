import React, { useState, useEffect } from 'react'
import './../../styles/home/home.css'
import '../../styles/events/events.css'
import { PageContainer } from '../../common/helpers'
import { Box, Button, Flex, Icon, Spinner, Text, useDisclosure, useToast } from '@chakra-ui/core'
import { useTranslation } from 'react-i18next'
import { getAuthToken, getUserCountryId, getUserRolId } from '../../../redux/selectors'
import {
  actualizarMedia,
  actualizarUrlVideo,
  crearMedia,
  crearUrlVideo,
  eliminarMedia,
  obtenerComunicados,
  obtenerComunicadosAll,
  obtenerListaDeMedia,
  obtenerPermisosPorRolId,
  obtenerUrlVideo,
} from '../../../api/communications'
import { useSelector } from 'react-redux'
import moment from 'moment'
import '../../styles/comunications/comunications.css'
import '../../styles/comunications/eventsHome.css'
import ModalSimple from '../../common/modal-simple'
import { isSuccess } from '../../../helpers/utils'
import { toastCustomizedError, toastCustomizedSuccess } from '../../common/toast-default-config'
import UpcomingEventsSlider from '../../events/upcomingEventsSlider'
import MediaSlider from '../../media/mediaSlider'
import MediaEditForm from '../../media/mediaEditForm'
import MediaCreateForm from '../../media/mediaCreateForm'

function UpcomingEvents() {
  const userCountryId = useSelector(getUserCountryId)
  const authToken = useSelector(getAuthToken)
  const { t } = useTranslation(['comunications', 'common', 'forms'])
  const userRolId = useSelector(getUserRolId)
  const permisosUser = userRolId == 1 ? true : false
  const permisosUserCountry = userRolId == 2 ? true : false
  const editMediaModal = useDisclosure()
  const [url, setUrl] = useState('')
  const [newUrl, setNewUrl] = useState('')
  const [urlId, setUrlId] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const [events, setEvents] = useState({
    loading: false,
    data: [],
    error: false,
  })
  const [media, setMedia] = useState({
    loading: false,
    data: null,
    error: false,
  })
  const [mediaEditable, setMediaEditable] = useState()
  const toast = useToast()

  // Obtener Permisos de Usuario
  async function obtenerPermisos() {
    try {
      const resp = await obtenerPermisosPorRolId(userRolId, authToken)
      getEvents(resp.data)
      setLoaded(true)
    } catch (err) {
      console.log(err)
    }
  }

  // Obtener Eventos
  // async function getEvents(permisos) {
  //   try {
  //     const searchCountry = permisosUser ? '' : userCountryId
  //     const respCalendar = await obtenerComunicadosAll(authToken, { paisId: searchCountry, tipoComunicadoId: 4})
  //     const respEvents = await obtenerComunicadosAll(authToken, { paisId: searchCountry, tipoComunicadoId: 2 })
  //     let filterCalendar = respCalendar.data.data.filter(x => x.fechaBaja == null)
  //     let filterEvents = respEvents.data.data.filter(x => x.fechaBaja == null)
  //     const concatEventsCalendar = filterCalendar.concat(filterEvents)
  //     const uniqueEventsCalendar = []
  //     concatEventsCalendar.forEach(item => {
  //       const i = uniqueEventsCalendar.findIndex(x => x.id === item.id)
  //       if (i <= -1) {
  //         return uniqueEventsCalendar.push(item)
  //       }
  //     })
  //     let perm = permisos
  //     perm.filter(x => x.rolId == userRolId)

  //     function filters(id) {
  //       let i = perm.map(x => x.comunicadosId == id)
  //       let k = i.filter(x => x == true)
  //       if (k == '') {
  //         return false
  //       } else {
  //         return true
  //       }
  //     }

  //     let filterPermisos = uniqueEventsCalendar.map(x => (filters(x.id) == true ? x : null))
  //     let filterPermisos2 = filterPermisos.filter(x => x !== null)
  //     let filterLastDat = filterPermisos2.sort((datoPrev, datoNext) => {
  //       return (
  //         new Date(moment(datoPrev.fecha).format('YYYY-MM-DD')).getTime() >
  //         new Date(moment(datoNext.fecha).format('YYYY-MM-DD')).getTime()
  //       )
  //     })
  //     let destacados = filterLastDat.filter(x => x.destacado == true)
  //     let noDestacados = filterLastDat.filter(x => x.destacado == false)

  //     const concatEventsHome = destacados.concat(noDestacados)
  //     setEvents(concatEventsHome)
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }

  async function getEvents(permisos) {
    try {
      //TODO: DEUDA TECNICA - Sacar esto y llevarlo al back
      const searchCountry = permisosUser ? '' : userCountryId
      setEvents({
        ...events,
        loading: true
      })
      const respCalendar = await obtenerComunicadosAll(authToken, { paisId: searchCountry, tipoComunicadoId: 4 })
      const respEvents = await obtenerComunicadosAll(authToken, { paisId: searchCountry, tipoComunicadoId: 2 })

      let filterCalendar = respCalendar.data
      let filterEvents = respEvents.data
      const concatEventsCalendar = filterCalendar.concat(filterEvents)
      const uniqueEventsCalendar = []
      concatEventsCalendar.forEach(item => {
        const i = uniqueEventsCalendar.findIndex(x => x.id === item.id)
        if (i <= -1) {
          return uniqueEventsCalendar.push(item)
        }
      })
      let perm = permisos
      perm.filter(x => x.rolId == userRolId)

      function filters(id) {
        let i = perm.map(x => x.comunicadosId == id)
        let k = i.filter(x => x == true)
        if (k == '') {
          return false
        } else {
          return true
        }
      }

      let filterPermisos = uniqueEventsCalendar.map(x => (filters(x.id) == true ? x : null))
      let filterPermisos2 = filterPermisos.filter(x => x !== null)
      let filterLastDat = filterPermisos2.sort((datoPrev, datoNext) => {
        return (
          new Date(moment(datoPrev.fecha).format('YYYY-MM-DD')).getTime() >
          new Date(moment(datoNext.fecha).format('YYYY-MM-DD')).getTime()
        )
      })
      let destacados = filterLastDat.filter(x => x.destacado == true)
      let noDestacados = filterLastDat.filter(x => x.destacado == false)

      const concatEventsHome = destacados.concat(noDestacados)
      const sortedUniqueEventsCalendar = uniqueEventsCalendar.sort((a, b) => {
        const dateA = new Date(a.fecha)
        const dateB = new Date(b.fecha)

        return dateA - dateB
      })
      setEvents({
        ...events,
        loading: false,
        data: sortedUniqueEventsCalendar
        // data: null
      })
    } catch (err) {
      console.log(err)
    }
  }

  async function updateVideo() {
    try {
      const res = await actualizarUrlVideo(urlId, { paisId: userCountryId, link: newUrl }, authToken)

      if (isSuccess(res.status)) {
        toast(toastCustomizedSuccess(t('forms:actionCompleted')))
        editMediaModal.onClose()
        getVideo()
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function getVideo() {
    try {
      const res = await obtenerUrlVideo(userCountryId, authToken)
      if (!res.data.length) {
        const resCreate = await crearUrlVideo(
          { paisId: userCountryId, link: 'https://www.youtube.com/embed/rkV3PWjCdLw' },
          authToken,
        )
        setUrl(resCreate.data?.link)
        setUrlId(resCreate.data?.id)
      } else {
        setUrl(res.data[0]?.link)
        setUrlId(res.data[0]?.id)
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function getMedia() {
    try {
      setMedia({
        ...media,
        loading: true,
      })
      const res = await obtenerListaDeMedia(userCountryId, authToken)
      setMedia({
        ...media,
        loading: false,
        data: res.data,
      })
      setMediaEditable(res.data)
    } catch (error) {
      setMedia({
        loading: false,
        error: true,
      })
      console.log(error)
    }
  }

  async function createMedia(mediaLink) {
    try {
      const res = await crearMedia({ paisId: userCountryId, link: mediaLink }, authToken)

      return isSuccess(res.status)
    } catch (error) {
      console.log(error)
    }
  }

  async function updateMedia(id, newLink) {
    try {
      const res = await actualizarMedia(id, { link: newLink }, authToken)

      return isSuccess(res.status)
    } catch (error) {
      console.log(error)
    }
  }

  async function deleteMedia(mediaId) {
    try {
      const res = await eliminarMedia(mediaId, authToken)

      return isSuccess(res.status)
    } catch (error) {
      console.log(error)
    }
  }

  async function handleDeleteMedia(id) {
    try {
      const deleted = await deleteMedia(id)
      handleToast(deleted)
      await reloadMedia()

    } catch (error) {
      console.log(error)
    }
  }

  async function handleUpdateMedia(id, value) {
    try {
      const updated = await updateMedia(id, value)
      handleToast(updated)
      await reloadMedia()
    } catch (error) {
      console.log(error)
    }
  }

  async function handleCreateMedia(value) {
    try {
      const created = await createMedia(value)
      handleToast(created)
      await reloadMedia()
    } catch (error) {
      console.log(error)
    }
  }

  function reloadMedia() {
    getMedia()
  }

  function handleToast(isSuccess) {
    if (isSuccess) {
      toast(toastCustomizedSuccess(t('forms:actionCompleted')))
    } else {
      toast(toastCustomizedError(t('forms:actionUncompleted', t('forms:support'))))
    }
  }

  useEffect(() => {
    getMedia()

    if (!loaded) {
      obtenerPermisos()
    }
  }, [])

  return (
    <Box backgroundColor='white' style={{ paddingTop: '30px', paddingBottom: '60px' }}>
      <PageContainer>
        <Box mx={10}>
          <Box className='upcoming-events-title'>{t('comunications:eventsAndTraining')}</Box>
          <Box className='divider-bar' />
          <Box>
            {!events.loading && events.data.length > 0 ? (
              <UpcomingEventsSlider events={events.data} perPage={6} />
            ) : events.loading ? (
              <Flex justifyContent={'center'} mt={'2rem'}>
                <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
              </Flex>
            ) : !events.loading && !events.data.length &&
            <Flex justifyContent='center' alignItems='center'>
            {t('comunications:noNewEventsAndTrainings')}
          </Flex>
          }
          </Box>
        </Box>
        <Box mx={10}>
          <Flex justifyContent='end' mt={8} mb={2}>
            {permisosUserCountry && (
              <Box>
                <Button variantColor='blue' onClick={() => editMediaModal.onOpen()}>
                  <span>
                    {t('common:edit')} {t('common:media')}
                  </span>
                </Button>
              </Box>
            )}
          </Flex>
          <Box>
            {media.data?.length > 0 && !media.loadig ? (
              <MediaSlider mediaList={media.data} />
            ) : media.loading ? (
              <Flex justifyContent={'center'} mt={'2rem'}>
                <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
              </Flex>
            ) : null}
          </Box>
        </Box>
      </PageContainer>
      <ModalSimple
        isOpen={editMediaModal.isOpen}
        onClose={editMediaModal.onClose}
        title={`${t('common:edit')} ${t('common:media')}`}
        size='xl'
      >
        <Text mb="8px" p={2} fontSize={"xs"} color={"gray.600"} backgroundColor={"#ceedff"}>
          <Flex alignItems="center">
            <Icon fontSize={"md"} name="info" mr={2} color={"blue.500"}/>
            <span>{t('common:media_message')}</span>
          </Flex>
        </Text>
        {media.data?.map(mediaItem => (
          <MediaEditForm
            key={mediaItem.id}
            media={mediaItem}
            onDelete={handleDeleteMedia}
            onUpdate={handleUpdateMedia}
          />
        ))}
        {media.data?.length < 5 && <MediaCreateForm onCreate={handleCreateMedia} />}
      </ModalSimple>
    </Box>
  )
}

export default UpcomingEvents
